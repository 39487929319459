import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CardHeader,
  Divider,
  Tooltip
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useLocation, useHistory } from 'react-router-dom';
import Page from 'src/components/Page';
import Toolbar from '../BackToolbar';
import { NewFormatDateShort } from 'src/Commons';
import useSessionTimeout from 'src/hook/AuthSesion';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';





const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spaceButtom: {
    paddingBottom: '1%'
  }
}));

const VerSucursal = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  // console.log(location.state)
  const data = location.state;
  useSessionTimeout();

  const goBackButton = () => {
    // history.push('services')
    history.push({
      pathname: 'Sucursal',
      state: { isRefetch: true }
    })  
  }
  return (
    <Page
      className={classes.root}
      title="Sucursales"
    >
      <Container maxWidth="lg">
        {/* <Toolbar className={classes.spaceButtom} /> */}
        <Card>
          <CardHeader
            title={`Sucursal ID: ${data.SUC_Id}` }
            style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  {/* <Button                                        
                    color="inherit"
                    size="large"             
                    onClick={ GoToNew}       
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button> */}
                {/* </RouterLink> */}

                <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </div>
            }
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                  Ubicación:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.PAI_Name}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                  Sucursal:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.SUC_Nombre}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                  Nafta por viaje:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">                
                  {`$${(data.SUC_Nafta).toFixed(2)}`}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                  Activa:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.SUC_Activo}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                  Fecha de Modificación:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {NewFormatDateShort(data.SUC_Fecha)}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                  Modificado por:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {(data.SUC_ByModify)}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default VerSucursal;
