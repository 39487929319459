import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Button, Card, CardHeader, Divider, Table, TableBody, 
TableCell, TableHead, TableRow, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useHistory } from 'react-router-dom';

// Estilos personalizados usando makeStyles
const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  customTableCell: {
    padding: '1px',
    borderBottom: '', // Elimina el borde inferior
  },
  colorTableA: {
    color: "Black",
    background: "#d4eaff",
    textAlign: "center",
  },
  colorTableC: {
    color: "white",
    background: "red",
    textAlign: "center",
  },
  colorTableP: {
    color: "Black",
    background: "#ffffc0",
    textAlign: "center",
  },
  colorTableR: {
    color: "white",
    background: "#FE9747",
    textAlign: "center",
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover, // Puedes cambiar esto al color que prefieras
    },
  },
}));

// Función para formatear la fecha
const NewFormatDateLong1 = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// Componente para mostrar el estado con un Chip
const StatusChip = ({ status }) => {
  const classes = useStyles();

  // Mapea el estado al estilo correspondiente
  const statusClass = {
    'Confirmado': classes.colorTableA,
    'Cancelado': classes.colorTableC,
    'Pendiente': classes.colorTableP,
    'Reprogramado': classes.colorTableR,
  }[status] || {};

  return (
    <TableCell>
      <Chip
        className={statusClass}
        label={status}
        size="small"
      />
    </TableCell>
  );
};

// Componente principal para mostrar las órdenes
const LatestOrders = ({ data }) => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const history = useHistory();
  
  useEffect(() => {
    setOrders(data); // Actualiza el estado con los datos recibidos
  }, [data]);

  const handleRedirect = () => {
    history.push('/app/reports/ReportLastOrder');
  };

  return (
    <Card>
      <CardHeader title="Ultimas Ordenes" style={{ backgroundColor: "#138cd4", color: '#FFFFFF' }}/>
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Sucursal</TableCell>
                <TableCell>Fecha de servicio</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow hover key={order.SERV_Id} className={classes.tableRow}>
                  <TableCell>{order.SERV_Id}</TableCell>
                  <TableCell>{order.SUC_Nombre}</TableCell>
                  <TableCell>{NewFormatDateLong1(order.SERV_Fecha)}</TableCell>
                  <StatusChip status={order.STATUS_Nombre} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={handleRedirect}
        >
          Ver mas
        </Button>
      </Box>
    </Card>
  );
};

// Exportando el componente principal
export default LatestOrders;
