import { useState } from 'react';
import { DecimalesInReport } from "src/Commons";
import {GET_CONSTDATOS} from "src/graphql/Const";
import { useLazyQuery } from '@apollo/react-hooks';


let X5 = 1320000;
let X6 = 1560000;
let X7 = 1800000;


export function RangoSell (Amount) {
    let RSell = 0;
    let XUsed = 0;

    if (Amount < X5) {
        RSell = (Amount * 0.05).toFixed(DecimalesInReport)
        XUsed = 5;
    } else if (Amount >= X5 && Amount <= X6) {
        RSell = (Amount * 0.06).toFixed(DecimalesInReport);
        XUsed = 6;
    } else if (X6 > Amount && Amount <= X7 ){
        RSell = (Amount * 0.06).toFixed(DecimalesInReport)
        XUsed = 6;
    }else {
        RSell = (Amount * 0.07).toFixed(DecimalesInReport)
        XUsed = 7;
    }

    return {RSell,XUsed}
  }