import { gql } from 'apollo-boost';


//query

export const GET_CONSTDATOS = gql`
  query GetConstDatos{
    GetConstDatos{
      COM_Id
      COM_Description
      COM_Amount
      COM_Procentaje      
    }
  }
`;