import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles,
  Grid,  
  Button
} from '@material-ui/core';
import { NewFormatDateShort, RowsxList } from '../../Commons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Visibility, Edit } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { GET_ALLDEPOSITOS } from 'src/graphql/Deposito';
import { useLazyQuery } from '@apollo/react-hooks';
import Toolbar from 'src/shared/componentes/Toolbar';

const columns = [
  { field: 'id', headerName: 'ID', width: 130 },
  { field: 'Fecha', headerName: 'Fecha', width: 130 },
  // { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'DP_Usuario', headerName: 'Usuario', width: 130 },
  // { field: 'DP_MtdDepo', headerName: 'Mtdo Deposito', width: 130 },
  { field: 'DP_Referencia', headerName: 'Concepto', width: 130 },
  { field: 'Monto', headerName: 'Monto', width: 130 },
  // { field: 'DP_Recibo', headerName: 'Recibo', width: 130 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '1%',
    marginRight: '1%'
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  }
}));

const DataTable = (props) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const location = useLocation();

  const handleClick = (tipo, data) => {
    if (tipo === 'edit') {
      history.push({
        pathname: 'editDeposito',
        state: data
      })
    } else {
      history.push({
        pathname: 'verDeposito',
        state: data
      })
    }
  }
  const [sucursalSearch, SetSucursalSearch] = useState('');
  const handlerChangeSuc = (e) => {
    SetSucursalSearch(e.target.value)
  }
  const onClickFunction = () => {
    // console.log('Buscando datos con sucursal:', sucursalSearch);
    if (sucursalSearch !== '') {
      searchData({ variables: { data: { sucursal: sucursalSearch } } })
    }
  }
  const [searchData, { loading: loading_Services, error: error_Services, data: servicesData }] = useLazyQuery(GET_ALLDEPOSITOS, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    let thing = []
    if (servicesData && loading_Services === false && error_Services === undefined) {
      servicesData.GetDepositos.map(item => {
        item['id'] = item.DP_Id;
        item['Fecha'] = NewFormatDateShort(item.DP_Fecha);
        item['Monto'] = "$" + (item.DP_Monto).toFixed(2);
        return thing.push(item)
      })
      setDataTable(thing);
    }
  }, [servicesData, error_Services, loading_Services, location])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goBackButton = () => {
    // history.push('services')
    history.push({
      pathname: 'depositos',
      state: { isRefetch: true }
    })  
  }

  const GoToNew = () => {
    history.push('/app/newDeposito')}

  return (
    <Card
     
    >
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          // className={classes.headerStyle}
          xs={12}
        >
          <CardHeader 
          title="Depositos" 
          style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  <Button                                        
                    color="inherit"
                    size="large"             
                    onClick={ GoToNew}       
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button>
                {/* </RouterLink> */}

                {/* <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip> */}
              </div>
            }
          />
          {/* <RouterLink className={classes.buttonStyle} to='/app/newDeposito'>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              startIcon={<AddIcon />}
            >
              Nuevo
            </Button>
          </RouterLink> */}
        </Grid>
        <Toolbar
          onClickFunction={onClickFunction}
          valueSuc={sucursalSearch}
          handlerChangeSuc={handlerChangeSuc}
          className={classes.spaceButtom}
        />
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                <TableCell className={classes.head} >
                  Ver
                </TableCell>
                <TableCell className={classes.head} >
                  Modificar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, i) => {
                return (
                  <TableRow style={i % 2 ? { background: "#f4f6f8" } : { background: "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Visibility onClick={() => handleClick('ver', order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit', order)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default DataTable;