import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  makeStyles
} from '@material-ui/core';
import { NewFormatDateLong } from '../../../Commons';

const useStyles = makeStyles((theme) => ({
  root: {},
  displayNone: {
    display: 'none'
  },
  displayBlock: {
    display: 'block'
  }
}));


const ContentText = (props) => {
  const classes = useStyles();
  const [naftaOp, setNaftaOp] = useState(0)
  useEffect(() => {
    if (props.naftaA.length > 0) {
      setNaftaOp(props.naftaA[0].SERV_ANafta)
    }
  }, [props])
  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        md={6}
        xs={12} 
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Fecha de creación:
                    </Typography>
                    
      </Grid >
      <Grid
        item
        md={6}
        xs={12}
        
      >
        <Typography variant="body2" component="p">
          {NewFormatDateLong(props.data.SERV_Fecha)}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Estado del servicio:
                    </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p">
          {props.data.STATUS_Nombre}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Sucursal:
                    </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p">
          {props.data.SUC_Nombre}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Barrio:
                    </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p">
          {props.data.ZON_Barrio}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Nombre cliente:
                    </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p">
          {props.data.CLI_NombreCom}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Telefono cliente:
                    </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p">
          {props.data.CLI_Telefono}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Email cliente:
                    </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p">
          {props.data.CLI_Email}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Dirección cliente:
                    </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p">
          {props.data.CLI_Direccion}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Referencia cliente:
                    </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography variant="body2" component="p">
          {props.data.CLI_Referencia}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}        
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Observación:
                </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        
      >
        <Typography variant="body2" component="p">
          {props.data.SERV_Observacion}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        className={naftaOp !== 0 ? classes.displayBlock : classes.displayNone}
      >
        <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
          Nafta adicional:
                </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        className={naftaOp !== 0 ? classes.displayBlock : classes.displayNone}
      >
        <Typography variant="body2" component="p">
          {`$${naftaOp.toFixed(2)}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ContentText;