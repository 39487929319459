import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useLazyQuery } from '@apollo/react-hooks';
import { LOGIN_USER } from '../../graphql/Login';
import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { switchAuth } from '../../Commons';
import Page from 'src/components/Page';

// Adaptar estilos al tema de la aplicación
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
    
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

// Componente de alerta
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginView = () => {
  const classes = useStyles();
  const history = useHistory();
  const [errores, setErrores] = useState('');
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [login, { loading }] = useLazyQuery(LOGIN_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      switch (data.UserValidate.message) {
        case 'Success':
          const objectSession = {
            USR_Id: data.UserValidate.USR_Id,
            USR_NombreCom: data.UserValidate.USR_NombreCom,
            USR_UGP_ID: data.UserValidate.USR_UGP_ID,
            USR_Disabled: data.UserValidate.USR_Disabled,
            USR_Active: data.UserValidate.USR_Active,
            UGP_Nombre: data.UserValidate.UGP_Nombre,
          };
          localStorage.setItem('sesTime', JSON.stringify(objectSession));
          localStorage.setItem('sucursalesUser', JSON.stringify(data.UserValidate.sucursales));
          localStorage.setItem('sessionStartTime', Date.now());
          const dataSwitch = switchAuth('dashboard', data.UserValidate.USR_UGP_ID);
          if (typeof dataSwitch !== 'boolean') {
            history.push(`/app/${dataSwitch}`);
          } else {
            history.push('/app/dashboard');
          }
          break;
        case 'newUser':
          localStorage.setItem('newUserId', data.UserValidate.USR_Id);
          history.push('/newUser');
          break;
        default:
          setState({ ...state, open: true });
          setErrores(data.UserValidate.message);
          break;
      }
    },
    onError: (error) => {
      setState({ ...state, open: true });
      setErrores('Error de conexión. Intenta nuevamente.');
    },
  });

  return (
    <Page className={classes.root} title="Login">

    
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar sesión
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const email = formData.get('email');
            const password = formData.get('password');
            await login({
              variables: {
                data: { id: email, password },
              },
            });
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recuérdame"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            {loading ? 'Iniciando sesión...' : 'Iniciar sesión'}
          </Button>
          <Grid container>
            <Grid item xs>
              {/* <Link href="#" variant="body2" onClick={() => history.push('404')}> */}
              <Link href="/404">
                ¿Olvidaste la contraseña?
              </Link>
            </Grid>
            <Grid item>
              {/* <Link href="#" variant="body2" onClick={() => history.push('/404')}>
                {"¿No tienes una cuenta? Regístrate"}
              </Link> */}
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        {/* <Copyright /> */}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity="error">{errores}</Alert>
      </Snackbar>
    </Container>

    </Page>
  );
}

export default LoginView;
