import React from 'react';
import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { CalendarColor } from 'src/Commons';
import { Card, CardContent } from '@material-ui/core';


const localizer = momentLocalizer(moment) // or globalizeLocalizer

const MyCalendar = (props) => {
    // const classes = useStyles();
    const classes = CalendarColor();
    return (
        <CardContent>
            <Card>
                <div>

                    <Calendar
                        localizer={localizer}
                        step={60}
                        messages={{
                            next: "Siguiente",
                            previous: "Atrás",
                            today: "Hoy",
                            month: "Mes",
                            week: "Semana",
                            day: "Dia",

                        }}
                        onSelectEvent={event => props.handleClick(event)}
                        defaultView='week'
                        showMultiDayTimes
                        events={props.events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        eventPropGetter={
                            (event) => {
                                let style = '';
                                switch (event.STATUS_Nombre) {
                                    case 'Confirmado':
                                        style = classes.colorTableA
                                        break;
                                    case 'Cancelado':
                                        style = classes.colorTableC
                                        break;
                                    case 'Pendiente':
                                        style = classes.colorTableP
                                        break;
                                    case 'Reprogramado':
                                        style = classes.colorTableR
                                        break;
                                    default:
                                        style = ''
                                        break;
                                }

                                return {
                                    className: style,
                                };
                            }
                        }
                    />
                </div>
            </Card>
        </CardContent>
    )
}

export default MyCalendar;