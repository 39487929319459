import { gql } from 'apollo-boost';

//Query
export const ALL_SERVICES = gql`
  query GetServices($data: GetDatFilterInput){
    GetServices(data: $data){
      SERV_Id
      SUC_Nombre
      SUC_CodePhone
      PAI_Code
      SERV_Fecha
      SERV_FechaF
      STATUS_Nombre
      CLI_Id
      CLI_DNI
      CLI_NombreCom
      CLI_Telefono
      CLI_Email
      CLI_Direccion
      CLI_Referencia
      SERV_TUR_Id
      TUR_Nombre
      ZON_Region
      ZON_Barrio
      ZON_Id
      SERV_Observacion
      SERV_Nafta
      SERV_ANafta
    }
  }
`;

export const ITEMS_SERVICES = gql`
  query GetItemServicios($data: InputsItemService){
    GetItemServicios(data:$data){
      SERV_SID
      SERV_SID_Id
      SERV_SID_Servicio
      TIPO_Nombre
      SERV_SID_Importe
      SERV_SID_MtdPago
      MTD_Nombre
      SERV_Nafta
      SERV_ANafta
    }
  }
`

//Mutation

export const NEW_SERVICES = gql`
  mutation createService($data: ServicesInput){
    createService(data: $data){
      SERV_Id 
      SERV_Fecha
      SERV_Sucursal
      SERV_Cliente
      SERV_Telefono
      SERV_Email
      SERV_Direccion
      SERV_Referencia
      SERV_Estado
      SERV_TUR_Id
      SERV_Usuario
      SERV_Ult_mod
      items {
        SERV_Servicio
        SERV_Importe
        SERV_Mtd_pago
      }
      Message
    }
  }
`;

export const EDIT_SERVICES = gql`
  mutation editServices($data: ServicesInput){
    editServices(data:$data){
      SERV_Id 
      SERV_Fecha
      SERV_Sucursal
      SERV_Cliente
      SERV_Telefono
      SERV_Email
      SERV_Direccion
      SERV_Referencia
      SERV_Estado
      SERV_TUR_Id
      SERV_Usuario
      SERV_Ult_mod
      items {
        SERV_Servicio
        SERV_Importe
        SERV_Mtd_pago
      }
      Message
      Response
    }
  }
`

export const DEL_SERVICES = gql`
  mutation delService($data: ServicesDelInput){
    delService(data:$data)
  }
`