import React from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import Page from 'src/components/Page';
// import Deposito from './Bancos';
// import BancosCajas from './BancosCajas';
import LatestOrders from './LatestOrders';
// import LatestProducts from './LatestProducts';
import Sales from './Sales';
import DashboardCardV2 from './Turnos3D';
// import TurnosAgendados from './TurnosAgendados';
// import TurnosEjecutados from './TurnosRealizados';
// import VentasDiarias from './VentasDiarias';
import LoadingSpiner from '../../../components/LoadingSpiner';
// import Cajas from './Cajas';
import TrafficByDevice from './TrafficByDevice';

import { ItemListComponentNormalValue } from '../../../components/ItemListComponent';
import { DecimalesInReport } from 'src/Commons';

import {
  GET_SERVICIO3D, GET_SERVICIOADD, GET_SERVICIOS_TURNOSREALIZADO,
  GET_VENTAS_DIARIAS, GET_LAST_ORDER, GETALLSELLSMONTH, GETALLSERVMONTH
} from "../../../graphql/Dashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { loading: loadingServicios, data: servicios } = useQuery(GET_SERVICIO3D, {
    fetchPolicy: "no-cache",
  });

  const { loading: loading_Services, data: servicesData } = useQuery(GET_SERVICIOADD, {
    fetchPolicy: "no-cache",
  });

  const { loading: loadingTurnosRealizado, data: turnosRealizados } = useQuery(GET_SERVICIOS_TURNOSREALIZADO, {
    fetchPolicy: "no-cache",
  });

  const { loading: loadingVentasDiarias, data: ventasDiarias } = useQuery(GET_VENTAS_DIARIAS, {
    fetchPolicy: "no-cache",
  });

  const { loading: loadingLastOrder, data: LastOrder } = useQuery(GET_LAST_ORDER, {
    fetchPolicy: "no-cache"
  });

  const { loading: loadingAllSellMonth, data: AllSellMonth } = useQuery(GETALLSELLSMONTH, {
    fetchPolicy: "no-cache"
  });

  const { loading: loadingAllServMonth, data: AllServMonth } = useQuery(GETALLSERVMONTH, {
    fetchPolicy: "no-cache"
  });

  
  const renderTurnosAgendados = (item, index) => (
    <ItemListComponentNormalValue
      key={index}
      title={item.SUC_Nombre}
      value={`${item.Turnos}`}
    />
  );


  
  const renderVentasDiarias = (item, index) => (
    <ItemListComponentNormalValue
      key={index}
      title={item.SUC_Nombre}
      value={`$ ${item.SERV_SID_Importe.toFixed(DecimalesInReport)}`}
    />
  );

  const renderTurnosRealizados = (item, index) => (
    <ItemListComponentNormalValue
      key={index}
      title={item.SUC_Nombre}
      value={`${item.Confirmado}/${item.TotalServicios}`}
    />
  );

  
  return (
    <Page
      className={classes.root}
      title="Dashboard - MAM"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            {loadingServicios ? (
              <LoadingSpiner estado={loadingServicios} />
            ) : 
            (
              // <DashboardCardV2 title={'Turnos a 3 Dias'} data={servicios?.GetServicio3dDashboard} />
              <DashboardCardV2
              title="Turnos a 3 Dias"
              data={servicios?.GetServicio3dDashboard}
              renderItem={renderTurnosAgendados}
            />
            )
            }
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            {loading_Services ? (
              <LoadingSpiner estado={loading_Services} />
            ) : (
              <>
                {/* {console.log('GetServicioADDDashboard en frontend:', servicesData?.GetServicioADDDashboard)} */}
                {/* <DashboardCardV2 title={'Turnos Agendados'} data={servicesData?.GetServicioADDDashboard}  /> */}
                {/* // Uso en el Dashboard */}
                <DashboardCardV2
                  title="Turnos Agendados"
                  data={servicesData?.GetServicioADDDashboard}
                  renderItem={renderTurnosAgendados}
                />
              </>
            )}
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            {loadingTurnosRealizado ? (
              <LoadingSpiner estado={loadingTurnosRealizado} />
            ) : (
              // <DashboardCardV2 title={'Turnos Realizados'} data={turnosRealizados?.GetServicioTurnosRealizados} />
              <DashboardCardV2
              title="Turnos Realizados"
              data={turnosRealizados?.GetServicioTurnosRealizados}
              renderItem={renderTurnosRealizados}
            />
            )}
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            {loadingVentasDiarias ? (
              <LoadingSpiner estado={loadingVentasDiarias} />
            ) : (
              // <DashboardCardV2 title={'Ventas Diarias'} data={ventasDiarias?.GetServicioVentasDiarias} />
              <DashboardCardV2
              title="Ventas Diarias"
              data={ventasDiarias?.GetServicioVentasDiarias}
              renderItem={renderVentasDiarias}
            />
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <BancosCajas />
          </Grid>           */}

          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            {loadingAllServMonth ? (
              <LoadingSpiner estado={loadingAllServMonth} />
            ) : (
              <>
                {/* {console.log('AllServMonth:', AllServMonth?.GetAllServMonth)} */}
                <TrafficByDevice data={AllServMonth?.GetAllServMonth || []} />
              </>
            )}
          </Grid>

          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            {loadingAllSellMonth ? (
              <LoadingSpiner estado={loadingAllSellMonth} />
            ) : (
              <>
                {/* {console.log('AllSellMonth:', AllSellMonth?.GetAllSellMonth)} */}
                <Sales data={AllSellMonth?.GetAllSellMonth || []} />
              </>
            )}
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
          >
            {loadingLastOrder ? (
              <LoadingSpiner estado={loadingLastOrder} />
            ) : (
              <>
                {/* {console.log('LastOrder:', LastOrder)} */}
                <LatestOrders data={LastOrder?.GetLastOrder || []} />
              </>
            )}
          </Grid>

          {/* <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts />
          </Grid> */}

        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
