import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  // Box,
  Button,
  makeStyles,
  Grid,
  TextField,
  Container,
  CardContent,
  Card,
  Tooltip
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { GET_DATA_SUCURSAL } from '../../graphql/Selects'
import { useQuery } from '@apollo/react-hooks';
import { MapeoSucursales } from '../../Commons';

import Snack from 'src/utils/Common';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const Toolbar = (props, { className, ...rest }) => {
  const classes = useStyles();
  const [sucursalesSelect, setSucursalesSelect] = useState([]);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('info');

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const onClickFunction = () => {
    if (props.valueSuc === '') {
      setSnackMessage('Seleccione una sucursal antes de buscar.');
      setSnackSeverity('error');
      setSnackOpen(true);
    } else {
      props.onClickFunction();
    }
  };

  const handleChangeHere = e => {
    props.handlerChangeSuc(e)
  }
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  useEffect(() => {
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setSucursalesSelect(thing)
    }
  }, [dataSucursal, loading4, error4])
  // console.log(sucUser)
  return (

    <Container maxWidth="lg">
      <CardContent>
        <Grid
          container
          spacing={2}
        >
          <Grid item md={4} xs={12}>
            <Tooltip title="Seleccionar Sucursal">
              <TextField
                fullWidth
                select
                name="SERV_Sucursal"
                // label="Sucursal"
                value={props.valueSuc}
                onChange={handleChangeHere}
              >
                {!!sucursalesSelect && (sucursalesSelect.map((option) => (
                  <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                    {option.label}
                  </MenuItem >
                )))}
              </TextField>
            </Tooltip>
          </Grid>

          <Grid item md={2} xs={12}>

            <Button
              color="primary"
              variant="contained"
              onClick={onClickFunction}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Snack
        open={snackOpen}
        handleClose={handleCloseSnack}
        message={snackMessage}
        severity={snackSeverity}
      />
    </Container >

  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
