import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Switch from '@material-ui/core/Switch';
import { EDIT_MTDPAGO } from '../../../../graphql/Mtdpago';
import { GET_TYPE_MTDB } from 'src/graphql/Selects';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snack from 'src/utils/Common';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null); // State para manejar errores
  const [dataType, setDataTypeMTDB] = useState([])


  const { data: dataTypeMTDB, loading: loading1, error: error1 } = useQuery(GET_TYPE_MTDB);

  const initialValues = {
    CTA_Nombre: location.state.CTA_Nombre,
    CTA_Tipo: location.state.CTA_Tipo,
    CTA_Activo: location.state.CTA_Activo !== 0 ? true : false,
    CTA_Id: location.state.CTA_Id
    
  }
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [sendMtdPago] = useMutation(EDIT_MTDPAGO, {
    onCompleted: data => {
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/MtdPago',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })
  useEffect(() => {    
    if (dataTypeMTDB && loading1 === false && error1 === undefined){
      let thing = []
      dataTypeMTDB.GetDataTypeMtdB.map(item => {
        return thing.push({label: item.CTA_Nombr, value: item.CTA_Id})
      })
      
      setDataTypeMTDB(thing)
    }
    // if (dataMtdPago && loading3 === false && error3 === undefined) {
    //   let thing = []
    //   dataMtdPago.GetDatamtdpago.map(item => {
    //     return thing.push({ label: item.mtd_nombre, value: item.mtd_id })
    //   })
    //   setDatasMtdPago(thing)
    // }
  }, [
    // dataMtdPago,
    // loading3,
    // error3
    dataTypeMTDB,
    loading1,
    error1
  ])

  const goBackButton = () => {
    // history.push('services')
    history.push({
      pathname: 'MtdPago',
      state: { isRefetch: true }
    })  
  }
  return (
    <Formik
    initialValues={initialValues}
    validationSchema={Yup.object().shape({      
      CTA_Nombre: Yup.string().max(25).required('Campo requerido!')        
    })}
    onSubmit={async (values, actions) => {
      // console.log('Debug', JSON.stringify(values))
      setError("Inesperado");
        await sendMtdPago({
          variables: {
            data: {
              CTA_Nombre: values.CTA_Nombre.toUpperCase(),
              CTA_Tipo: values.CTA_Tipo,
              CTA_Activo: values.CTA_Activo !== false ? 1 : 0,
              CTA_Id: values.CTA_Id,
              CTA_ByModify: itemSession.USR_Id,
            },            
          }
        });
        actions.resetForm({
          values: {
            CTA_Nombre: '',
            
          },
          // you can also set the other form states here
        });
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              title={`Editando Metodo de pago: ${location.state.CTA_Nombre}`}
              style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
              action={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  {/* <Button
                    color="inherit"
                    size="large"
                    onClick={GoToNew}
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button> */}
                  {/* </RouterLink> */}

                  <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
                </div>
              }
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >                
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nombre Metodo de pago"
                    name="CTA_Nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.CTA_Nombre}
                    error={touched.CTA_Nombre && !!errors.CTA_Nombre}
                    helperText={touched.CTA_Nombre && errors.CTA_Nombre}                   
                  />                 
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    label="Tipo de cuenta"
                    name="CTA_Tipo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.CTA_Tipo}
                    error={touched.CTA_Tipo && !!errors.CTA_Tipo}
                    helperText={touched.CTA_Tipo && errors.CTA_Tipo}                   
                    >
                      {!!dataType && (dataType.map((option)=> (
                        <MenuItem key={`CTA_${option.value}`} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )))}
                    </TextField>
                                   
                </Grid>
                <Grid 
                  item
                  md={12}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="subtitle2">
                        Activo
                    </Typography>
                    <Switch size="small" name="CTA_Activo" checked={values.CTA_Activo} onChange={handleChange} />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Guardar
                </Button>
            </Box>
          </Card>          
          <Snack open={open} handleClose={handleClose} message={error ? error : " ¡ Metodo actualizado ! "}/>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;