import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  CardHeader,
  Divider,
  Grid,
  Box,
  Button,
  Tooltip
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as WhatsappIcon } from './whatsapp.svg';
import Swal from 'sweetalert2'

import { NewFormatDateLong } from '../../../Commons';

import { useLocation, useHistory } from 'react-router-dom';
import Page from 'src/components/Page';
import { useQuery } from '@apollo/react-hooks';
import { ITEMS_SERVICES } from '../../../graphql/Services'
import ItemContent from './ItemContent';
import ContentText from './ContentText';
import useSessionTimeout from 'src/hook/AuthSesion';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spaceButtom: {
    paddingBottom: '1%'
  },
  goBackButton: {
    display: 'flex',
    marginTop: '1%',
    marginRight: '1%',
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));


const VerServicios = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  useSessionTimeout();
  // console.log(data)
  // console.log('Debug ',JSON.stringify(data))
  const [dataQuery, setDataQuery] = useState([]);
  const [disableButtonWs] = useState(data.STATUS_Nombre === "Confirmado" ? false : true);
  const { loading, error, data: responseQuery, refetch } = useQuery(ITEMS_SERVICES, {
    fetchPolicy: "no-cache",
    variables: { data: { id: location.state.SERV_Id } }
  });
  const goBackButton = () => {
    history.push('services')
  }
  const onClickWsButton = async () => {
    let data = location.state
    let i = 0
    let inputValue
    let direccion = data.CLI_Direccion
    let fecha = NewFormatDateLong(data.SERV_Fecha)
    // let url = 'https://wa.me/'
    let Code = data.PAI_Code.replace(/\+/g, '')
    let url = `https://api.whatsapp.com/send/?phone=+`
    let Saludo = `Programación para el *${fecha}*%0A`
    let Barrio = `en el Barrio: *${data.ZON_Barrio}*%0A`
    let Cliente = `Cliente: *${data.CLI_NombreCom}*%0A`
    let Telefono = `Teléfono: *${data.CLI_Telefono}*%0A`
    let Direccion = `Dirección: *${direccion}*%0A`
    let Adicional = `Adicional de Nafta de: *${data.SERV_ANafta}*%0A`
    let Observation = `Observación: *${data.SERV_Observacion}*%0A`
    let string = `actividad a realizar:%0A`
    dataQuery.map((item) => {
      string += `*${i + 1}* *${item.TIPO_Nombre}*%0A`
      string += `por $${item.SERV_SID_Importe}*%0A`
      string += `pagados en *${item.MTD_Nombre}*.%0A`
      return i = i + 1
    })
    const { value: numeroTlf } = await Swal.fire({
      title: 'Teléfono',
      input: 'number',
      inputLabel: 'Número que recibe el mensaje',
      inputValue: inputValue,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Es necesario un numero de telefono!!'
        }
      }
    });
    if (numeroTlf) {
      url += `${Code}${numeroTlf}&text=${Saludo}${Barrio}${Cliente}${Telefono}${Direccion}${Observation}${Adicional}${string}&app_absent=0`
      // console.log(url);
      window.open(url, '_blank');
    }
  }
  useEffect(() => {
    if (responseQuery && loading === false && error === undefined) {
      let thing = []
      responseQuery.GetItemServicios.map(item => {
        return thing.push(item)
      })
      setDataQuery(thing);
    }
  }, [responseQuery, setDataQuery, loading, error, location, refetch])
  return (
    <Page
      className={classes.root}
      title="Servicios"
    >
      <Container maxWidth="lg">
        <Card>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              // className={classes.headerStyle}
              xs={12}
            >
              <CardHeader
                title={`Servicio: ${data.SERV_Id}`}
                style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                action={
                  <Box className={classes.goBackButton}>

                    <Tooltip title="Enviar WhatsApp">
                      <Button

                        disabled={disableButtonWs}
                        type="button"
                        // variant="contained"
                        color="inherit"
                        size="large"
                        onClick={onClickWsButton}
                      >
                        <SvgIcon component={WhatsappIcon} viewBox="0 0 600 476.6" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        // onClick={() => {
                        //   history.push('.');
                        // }}
                        onClick={goBackButton}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }

              />              
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <ContentText data={data} naftaA={dataQuery} />
          </CardContent>
          <CardHeader
            title={`Detalles del Servicios`}
          />
          {!!dataQuery && (dataQuery.map((row, i) => {
            return (
              <ItemContent row={row} i={i} key={i} />
            )
          }))}
        </Card>
      </Container>
    </Page>
  )
}

export default VerServicios;