import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Typography,
  List,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';



const DashboardCardV2 = React.memo(({ data = [], title, renderItem }) => {
  return (
    <Card>
      <CardHeader
        style={{ backgroundColor: "#138cd4", color: '#FFFFFF' }}
        title={title}
      />
      <CardContent>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 14, paddingRight: 18  }}>
            <Typography variant="h6">
              Sucursal
            </Typography>
            <Typography variant="h6">
              Detalle
            </Typography>
          </div>
          <PerfectScrollbar style={{ height: 165 }}>
            <List dense={true}>
              {data.length > 0 && data.map((item, index) => renderItem(item, index))}
            </List>
          </PerfectScrollbar>
        </div>
      </CardContent>
    </Card>
  );
});

export default DashboardCardV2;

// import React from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar'
// import {
//   Typography,
//   List,
//   Card,
//   CardHeader,
//   CardContent,
// } from '@material-ui/core';
// import { ItemListComponentNormalValue } from '../../../components/ItemListComponent';
// import { DecimalesInReport } from 'src/Commons';

// const DashboardCardV2 = ({ data, title }) => {
//   return (
//     <Card>
//       <CardHeader
//         style={{ backgroundColor: "#138cd4", color: '#FFFFFF' }}
//         title={title}
//       />
//       <CardContent>
//         <div>
//           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 14, paddingRight: 18  }}>
//             <Typography variant="h6">
//               Sucursal
//             </Typography>
//             <Typography variant="h6">
//               Turnos
//             </Typography>
//           </div>
//           <PerfectScrollbar
//             style={{ height: 165 }}
//           >
//             <List dense={true}>
//               {data !== undefined && data.length > 0 && (
//                 data?.map((item, index) => {
//                   // Si se necesita otra tipo de data se agrega un nuevo if
//                   if (title === 'Ventas Diarias') {
//                     return (
//                       <ItemListComponentNormalValue
//                         key={index}
//                         title={item.SUC_Nombre}
//                         value={`$ ${item.SERV_SID_Importe.toFixed(DecimalesInReport)}`}
//                       />
//                     );
//                   }
//                   if (title === 'Turnos Realizados') {
//                     return (
//                       <ItemListComponentNormalValue                        
//                         key={index}
//                         title={item.SUC_Nombre}
//                         value={`${item.Confirmado}/${item.TotalServicios}`}
//                       />
//                     );
//                   }
//                   if (title === 'Turnos Agendados'){
//                     return (
//                       <ItemListComponentNormalValue                        
//                         key={index}
//                         title={item.SUC_Nombre}
//                         value={`${item.Turnos}`}
//                       />
//                     );
//                   }

//                   return (
//                     <ItemListComponentNormalValue               
//                       key={index}
//                       title={item.SUC_Nombre}
//                       value={item.Turnos}
//                     />
//                   );
//                 })
//               )}
//             </List>
//           </PerfectScrollbar>
//         </div>
//       </CardContent>
      
//     </Card>
//   )
// }

// export default DashboardCardV2;
