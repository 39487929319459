import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import { GET_ALL_SELLaBILL } from "../../graphql/Report";
// import { GET_DATA_SUCURSAL } from "../../graphql/Selects";
// import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { DecimalesInReport } from 'src/Commons';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';


const columns = [
  { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'SUM_SERV_SID_Importe', headerName: 'Ventas del Dia', width: 130 },
  { field: 'Turnos', headerName: 'Turnos ', width: 100 },
  { field: 'SUM_SERV_SID_ImporteNeto', headerName: 'Comision', width: 130 },
  { field: 'SUM_DP_Monto', headerName: 'Gastos', width: 130 },
  { field: 'SUM_GAST_Importe', headerName: 'Deposito', width: 130 },
  { field: 'Diferencia', headerName: 'Diferencia', width: 130 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }
}));


const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  // const [datasSucursal, setDatasSucursal] = useState();
  // const { data: dataSucursal, loading, error } = useQuery(GET_DATA_SUCURSAL);

  const history = useHistory();

  const [findSales] = useLazyQuery(GET_ALL_SELLaBILL, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error('Error al ejecutar la consulta:', error);
    },
    onCompleted: (data) => {
      setDataTable(data.GetAllSellaBill);
      // console.log('DEBUG', data.GetAllSellaBill);
    },
  })

  // useEffect(() => {
  //   // Cuando el componente se monta, cargar los datos
  //   findSales({
  //     variables: {
  //       data: {
  //         FechaIni: getFirstDayOfMonth(),
  //         FechaFin: getLastDayOfMonth()
  //       },
  //     }
  //   });
  // }, [findSales]);
   // Ejecuta la búsqueda inicial cuando el componente se monta
   useEffect(() => {
    findSales({
      variables: {
        data: {
          FechaIni: getFirstDayOfMonth(),
          FechaFin: getLastDayOfMonth()
        },
      }
    });
  }, [findSales]); // Solo se ejecuta una vez al montar el componente

  // Función para obtener el último día del mes actual
  const getLastDayOfMonth = () => {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return lastDay.toISOString().split('T')[0];
  };

  // Función para obtener el primer día del mes actual
  const getFirstDayOfMonth = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    return firstDay.toISOString().split('T')[0];
  };

  return (
    <Page
      className={classes.root}
      title="Informe de rendicion"
    >
      <Formik
        initialValues={{
          FechaIni: getFirstDayOfMonth(),
          FechaFin: getLastDayOfMonth()
        }}
        onSubmit={async (values) => {

          await findSales({
            variables: {
              data: {
                FechaIni: values.FechaIni,
                FechaFin: values.FechaFin,
              },
            }
          })

        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
        }) => (
          <Container maxWidth="lg">
            
            <CardContent>
              <Card>
                <CardHeader
                  title="Informe de rendiciones"
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          // Lógica para navegar hacia atrás                          
                          // Puedes usar router para redirigir, por ejemplo:
                          history.push('.');

                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"
                          name="FechaIni"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaIni}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaIni && touched.FechaIni ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaIni}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"
                          name="FechaFin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFin}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaFin && touched.FechaFin ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaFin}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          {columns.map((rowsName) => (
                            <TableCell align="center" className={classes.head} key={rowsName.field}>
                              {rowsName.headerName}
                            </TableCell>

                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {!!dataTable.AllSellaBill && (dataTable.AllSellaBill.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align='center'>{row.SUC_Nombre}</TableCell>
                            {/* <TableCell align='center'>
                              {`${(row.SUM_SERV_SID_Importe ?? 0).toFixed(DecimalesInReport)} (${row.Turnos ?? 0})`}
                            </TableCell> */}
                            <TableCell align='center'>{(row.SUM_SERV_SID_Importe ?? 0).toFixed(DecimalesInReport)}</TableCell>                            
                            <TableCell align='center'>{row.Turnos ?? 0}</TableCell>
                            <TableCell align='center'>{row.SUM_SERV_SID_ImporteNeto.toFixed(DecimalesInReport) ?? 0}</TableCell>
                            <TableCell align='center'>{row.SUM_DP_Monto ? row.SUM_DP_Monto.toFixed(DecimalesInReport) : '0'}</TableCell>
                            <TableCell align='center'>{row.SUM_GAST_Importe ? row.SUM_GAST_Importe.toFixed(DecimalesInReport) : '0'}</TableCell>
                            <TableCell align='center'>{row.Diferencia.toFixed(DecimalesInReport) ?? 0}</TableCell>
                          </TableRow>
                        ))
                        )}



                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </CardContent>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-end"
            >
              <Grid
                item
                md={6}
                xs={12}
              >

                <CardContent>
                  <Card>
                    <Divider />
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="spanning table">
                        <TableBody>
                          <TableRow>
                            {/* <TableCell rowSpan={9} />
                            <TableCell> Acumula</TableCell>
                            <TableCell align='right'></TableCell> */}
                          </TableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </CardContent>
              </Grid>

            </Grid>


          </Container>

        )}
      </Formik>
    </Page>
  );
}


export default DataTable;
