import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CardHeader,
  Divider,
  Button,
  Tooltip
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Page from 'src/components/Page';
import { NewFormatDateShort } from 'src/Commons';
import ModalItem from 'src/components/ModalImage';
import useSessionTimeout from 'src/hook/AuthSesion';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spaceButtom: {
    paddingBottom: '1%'
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  buttonGoBack: {
    marginTop: '1%',
    marginRight: '1%',
  },
}));

const VerDepositos = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const setOpenModal = () => {
    setOpen(!open)
  }
  const goBackButton = () => {
    history.push('depositos')
  }
  const data = location.state;
  useSessionTimeout();
  return (
    <Page
      className={classes.root}
      title="Deposito"
    >
      <Container maxWidth="lg">
        <Card>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              // className={classes.headerStyle}
              xs={12}
            >
              <CardHeader
                title={`Deposito ID: ${data.DP_Id}`}
                style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                action={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                      {/* <Button                                        
                        color="inherit"
                        size="large"             
                        onClick={ GoToNew}       
                        startIcon={<AddIcon />}
                      >
                        Nuevo
                      </Button> */}
                    {/* </RouterLink> */}
    
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        // onClick={() => {
                        //   history.push('.');
                        // }}
                        onClick={goBackButton}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              />
              {/* <Button
                className={classes.buttonGoBack}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={goBackButton}
              >
                Atras
              </Button> */}
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Creado por usuario:
                    </Typography>
              </Grid >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.DP_Usuario}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Concepto de deposito:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.DP_Referencia}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Método de pago:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.MTD_Nombre}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Fecha:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {NewFormatDateShort(data.DP_Fecha)}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Sucursal:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.SUC_Nombre}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Importe:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {`$${(data.DP_Monto).toFixed(2)}`}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Recibo:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <img alt={data.DP_Referencia} src={data.DP_Recibo} width={100} onClick={(e) => { setOpenModal() }} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <ModalItem estado={open} openModal={setOpenModal} content={data.DP_Recibo} />
    </Page>
  )
}

export default VerDepositos;
