import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CardHeader,
  Divider,
} from '@material-ui/core';
import { NewFormatDateShort } from '../../../Commons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import Toolbar from '../BackToolbar';
import useSessionTimeout from 'src/hook/AuthSesion';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spaceButtom: {
    paddingBottom: '1%'
  }
}));

const Inventario = () => {
  const classes = useStyles();
  const location = useLocation();
  // console.log(location.state)
  const data = location.state;
  useSessionTimeout();
  return (
    <Page
      className={classes.root}
      title="Inventario"
    >
      <Container maxWidth="lg">
        <Toolbar className={classes.spaceButtom} />
        <Card>
          <CardHeader
            title={`Inventario ID: ${data.AF_Id}`}
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Creado por usuario:
                    </Typography>
              </Grid >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.AF_ByModify}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Fecha:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">

                  {NewFormatDateShort(data.AF_FechaCompra)}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Sucursal Asignada:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.SUC_Nombre}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Nombre Equipo:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.AF_Nombre}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Modelo de Equipo:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.AF_Modelo}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Serial Equipo:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.AF_Serial}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Asignada a:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.AF_Asignada}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Proveeedor:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.AF_Proveedor}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Factura N°:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.AF_Factura}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Costo Equipo:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {`$${(data.AF_Costo).toFixed(2)}`}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default Inventario;
