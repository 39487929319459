import React from 'react';
import Page from 'src/components/Page';
import {
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingBottom: theme.spacing(3) // Agregar espacio para el pie de página
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}));

const ListReport = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Lista de Reporte"
    >
      <Formik initialValues={{}}>
        {({
          values,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          errors
        }) => (        
          <Container maxWidth="lg">
            <CardContent>
              <Card>
                <CardHeader
                  title="Informes" align="center"
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Card className={classes.root}>
                        <Link to="/app/reports/ReportSaleDay">
                          <CardContent align="center">
                            <Typography
                              variant="h5"
                              component="h2"
                              align="center"
                            >
                              Reporte Diario Operaciones
                            </Typography>
                          </CardContent>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Card className={classes.root}>
                        <Link to="/app/reports/ReportTurAdduser">
                          <CardContent align="center">
                            <Typography
                              variant="h5"
                              component="h2"
                              align="center"
                            >
                              Informe turnos Usuario
                            </Typography>
                          </CardContent>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Card className={classes.root}>
                        <Link to="/app/reports/ReportRendicion">
                          <CardContent align="center">
                            <Typography
                              variant="h5"
                              component="h2"
                              align="center"
                            >
                              Informe de rendicion
                            </Typography>
                          </CardContent>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Card className={classes.root}>
                        <Link to="/app/reports/ReportPago">
                          <CardContent align="center">
                            <Typography
                              variant="h5"
                              component="h2"
                              align="center"
                            >
                              Reporte de sueldos
                            </Typography>
                          </CardContent>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Card className={classes.root}>
                        <Link to="/app/reports/ReportTurAdduserDay">
                          <CardContent align="center">
                            <Typography
                              variant="h5"
                              component="h2"
                              align="center"
                            >
                              Reporte Turnos Agendados
                            </Typography>
                          </CardContent>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Card className={classes.root}>
                        <Link to="/app/reports/ReportClient">
                          <CardContent align="center">
                            <Typography
                              variant="h5"
                              component="h2"
                              align="center"
                            >
                              Reporte de Cliente
                            </Typography>
                          </CardContent>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Card className={classes.root}>
                        <Link to="/app/reports/ReportLastOrder">
                          <CardContent align="center">
                            <Typography
                              variant="h5"
                              component="h2"
                              align="center"
                            >
                              Reporte Ultimas Ordenes
                            </Typography>
                          </CardContent>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Card className={classes.root}>
                        <Link to="/app/reports/ReportTopServices">
                          <CardContent align="center">
                            <Typography
                              variant="h5"
                              component="h2"
                              align="center"
                            >
                              Reporte Servicios Por zona
                            </Typography>
                          </CardContent>
                        </Link>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </CardContent>
          </Container>
          
        )}
      </Formik>      
    </Page>
  );
}

export default ListReport;
