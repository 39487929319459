import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  GET_DATA_SUCURSAL
} from '../../../graphql/Selects';
import { NEW_LEGAJO } from '../../../graphql/Legajo';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { MapeoSucursales } from '../../../Commons';
import Snack from 'src/utils/Common';


const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));

  const [ setDatasSucursal] = useState([])
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  const [sendLegajo] = useMutation(NEW_LEGAJO, {
    onCompleted: data => {
      // console.log(data)
      if (data) {
        setOpen(true)
        setTimeout(() => {
          history.push({
            pathname: '/app/Legajo',
            state: { isRefetch: true },
          });
        }, 1000)
      }
    }
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (dataSucursal && !loading4  && !error4 ) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
  }, [dataSucursal,
    loading4,
    error4,
    setDatasSucursal
  ])
  return (
    <Formik
      initialValues={{
        FIC_FIngreso: "",
        FIC_TContrato: "",
        FIC_Fecha_Nac: "",
        FIC_Dni: "",
        FIC_NombreCom: "",
        FIC_Mail: "",
        FIC_Telefono: "",
        FIC_Celular: "",
        FIC_Direccion: "",
        FIC_Cpostal: "",
        FIC_Nacionalidad: "",
        FIC_EstCivil: "",
        FIC_TUser: "",
        FIC_Sucursal: "",
        FIC_Vregistro: "",
        FIC_ENombre: "",
        FIC_ETelefono: "",
        FIC_SeNombre: "",
        FIC_TSeguro: "",
        FIC_Poliza: "",
        FIC_Observacion: "",
      }}
      validationSchema={Yup.object().shape({
        FIC_FIngreso: Yup.date().required('Requerida Fecha de ingreso.'),
        FIC_TContrato: Yup.date().required('Requerida Fecha de ingreso.'),
        // FIC_Fecha_Nac: Yup.date().required('Requerido Fecha de Nacimiento.'),
        FIC_Dni: Yup.string().max(11).required('Requerido DNI del empleado.'),
        FIC_NombreCom: Yup.string().max(100).required('Requerido Nombre del empleado.'),
        FIC_Mail: Yup.string().max(100).required('Requerido mail del empleado.'),
        FIC_Telefono: Yup.string().max(15).required('Requedido Telefóno fijo.'),
        FIC_Celular: Yup.string().max(15).required('Requedido Telefóno celular.'),
        FIC_Direccion: Yup.string().max(200).required('Requerido dirección precisa.'),
        FIC_Cpostal: Yup.string().max(10).required('Requerido codigo postal.'),
        FIC_Nacionalidad: Yup.string().max(20).required('Requerido nacionalidad.'),
        // FIC_EstCivil: Yup.string().max(10).required('Requerido estado civil.'),
        // FIC_TUser: Yup.string(),
        // FIC_Sucursal: Yup.string(),
        // FIC_Vregistro: Yup.date().required('Requerido fecha de vencimiento del registro.'),
        // FIC_ENombre: Yup.string().max(100).required('Requerido nombre de persona en caso de emergencia.'),
        // FIC_ETelefono: Yup.string().max(15).required('Requerido telefóno de emergencia.'),
        // FIC_SeNombre: Yup.string().max(100).required('Requerido nombre del seguro.'),
        // FIC_TSeguro: Yup.string().max(15).required('Requerido telefóno del seguro.'),
        // FIC_Poliza: Yup.string().max(15).required('Requerido numero de poliza.'),
        FIC_Observacion: Yup.string(),
        FIC_ByModify: Yup.string(),
      })}
      onSubmit={async (values) => {
        // console.log(values)
        await sendLegajo({          
          variables: {
            data: {
              FIC_FIngreso: values.FIC_FIngreso,
              FIC_TContrato: values.FIC_TContrato,
              FIC_Fecha_Nac: values.FIC_Fecha_Nac,
              FIC_Dni: values.FIC_Dni,
              FIC_NombreCom: values.FIC_NombreCom,
              FIC_Mail: values.FIC_Mail,
              FIC_Telefono: values.FIC_Telefono,
              FIC_Celular: values.FIC_Celular,
              FIC_Direccion: values.FIC_Direccion,
              FIC_Cpostal: values.FIC_Cpostal,
              FIC_Nacionalidad: values.FIC_Nacionalidad,
              // FIC_EstCivil: values.FIC_EstCivil,
              // FIC_TUser: values.FIC_TUser,
              // FIC_Sucursal: values.FIC_Sucursal,
              // FIC_Vregistro: values.FIC_Vregistro,
              // FIC_ENombre: values.FIC_ENombre,
              // FIC_ETelefono: values.FIC_ETelefono,
              // FIC_SeNombre: values.FIC_SeNombre,
              // FIC_TSeguro: values.FIC_TSeguro,
              // FIC_Poliza: values.FIC_Poliza,
              FIC_Observacion: values.FIC_Observacion,
              FIC_ByModify: itemSession.USR_Id
            },
          }
        })

      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
        
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              // subheader="Ficha"
              title="Legajo"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    className={classes.field}
                    defaultValue={moment(values.start).format('YYYY-MM-DDThh:mm:ss')}
                    fullWidth
                    type='date'
                    label="Fecha de ingreso:"
                    name="FIC_FIngreso"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.FIC_FIngreso}
                  />
                  {errors.FIC_FIngreso && touched.FIC_FIngreso ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_FIngreso}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    type = "date"
                    label="Tiempo de Contracto"
                    name="FIC_TContrato"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.FIC_TContrato}
                  >
                  </TextField>
                  {errors.FIC_TContrato && touched.FIC_TContrato ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_TContrato}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth                    
                    type="date"
                    label="Fecha de Nacimiento:"
                    name="FIC_Fecha_Nac"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.FIC_Nacimiento}
                  />
                  {errors.FIC_Nacimiento && touched.FIC_Nacimiento ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Nacimiento}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="DNI - CUIL - PASS:"
                    name="FIC_Dni"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.FIC_Dni}
                  >
                  </TextField>
                  {errors.FIC_Dni && touched.FIC_Dni ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Dni}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nombre Completo:"
                    name="FIC_NombreCom"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.FIC_Nombrec}
                  >
                  </TextField>
                  {errors.FIC_Nombrec && touched.FIC_Nombrec ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Nombrec}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Email:"
                    name="FIC_Mail"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.FIC_Mail}
                  >
                  </TextField>
                  {errors.FIC_Mail && touched.FIC_Mail ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Mail}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Telefono:"
                    name="FIC_Telefono"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_Telefono}
                  />
                  {errors.FIC_Telefono && touched.FIC_Telefono ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Telefono}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Celular:"
                    name="FIC_Celular"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_Celular}
                  />
                  {errors.FIC_Celular && touched.FIC_Celular ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Celular}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Dirección:"
                    name="FIC_Direccion"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_Direccion}
                  />
                  {errors.FIC_Direccion && touched.FIC_Direccion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Direccion}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Codigo postal:"
                    name="FIC_Cpostal"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_Cpostal}
                  />
                  {errors.FIC_Cpostal && touched.FIC_Cpostal ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Cpostal}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nacionalidad:"
                    name="FIC_Nacionalidad"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_Nacionalidad}
                  />
                  {errors.FIC_Nacionalidad && touched.FIC_Nacionalidad ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Nacionalidad}
                    </Typography>
                  ) : null}
                </Grid>
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Estado civil:"
                    name="FIC_EstCivil"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_EstCivil}
                  />
                  {errors.FIC_EstCivil && touched.FIC_EstCivil ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_EstCivil}
                    </Typography>
                  ) : null}
                </Grid> */}
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Tipo de Usuario:"
                    name="FIC_TUser"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_TUser}
                  />
                  {errors.FIC_TUser && touched.FIC_TUser ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_TUser}
                    </Typography>
                  ) : null}
                </Grid> */}
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Pertenece a la:"
                    name="FIC_Sucursal"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.FIC_Sucursal}
                  />
                  {errors.FIC_Sucursal && touched.FIC_Sucursal ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Sucursal}
                    </Typography>
                  ) : null}
                </Grid> */}
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    type="date"
                    label="Fecha Vencimiento registro:"
                    name="FIC_Vregistro"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.FIC_Vregistro}
                  />
                  {errors.FIC_Vregistro && touched.FIC_Vregistro ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Vregistro}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Avisar en caso de emergencia:"
                    name="FIC_ENombre"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_ENombre}
                  />
                  {errors.FIC_ENombre && touched.FIC_ENombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_ENombre}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Telefonó de emergencia:"
                    name="FIC_ETelefono"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_ETelefono}
                  />
                  {errors.FIC_ETelefono && touched.FIC_ETelefono ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_ETelefono}
                    </Typography>
                  ) : null}
                </Grid> */}
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth                    
                    label="Observación:"
                    name="FIC_Observacion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_Observacion}
                  />
                  {errors.FIC_Observacion && touched.FIC_Observacion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Poliza}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
            {/* <Divider />
            <CardContent>
              <CardHeader
                subheader="Datos del seguro"
                title="Seguro"
              />
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nombre del Seguro:"
                    name="FIC_SeNombre"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_SeNombre}
                  />
                  {errors.FIC_SeNombre && touched.FIC_SeNombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_SeNombre}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Telefóno del Seguro:"
                    name="FIC_TSeguro"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_TSeguro}
                  />
                  {errors.FIC_TSeguro && touched.FIC_TSeguro ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_TSeguro}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Poliza:"
                    name="FIC_Poliza"
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.FIC_Poliza}
                  />
                  {errors.FIC_Poliza && touched.FIC_Poliza ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.FIC_Poliza}
                    </Typography>
                  ) : null}
                </Grid>

              </Grid>
            </CardContent>
            <Divider /> */}
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Guardar
          </Button>
            </Box>
          </Card>

          
          <Snack open={open} handleClose={handleClose} message={error4 ? error4 : " ¡ Legajo creado ! "}/>
          
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;