import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Switch from '@material-ui/core/Switch';

import { EDIT_ADDSERVICIOS } from '../../../../graphql/AddServicios';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snack from 'src/utils/Common';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';





const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);

  const initialValues = {
    TIPO_Nombre: location.state.TIPO_Nombre,
    TIPO_Activo: location.state.TIPO_Activo !== 0 ? true : false,
    TIPO_Id: location.state.TIPO_Id
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [sendMtdPago] = useMutation(EDIT_ADDSERVICIOS, {
    onCompleted: data => {
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/AddServicios',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })
  const goBackButton = () => {
    // history.push('services')
    history.push({
      pathname: 'AddServicios',
      state: { isRefetch: true }
    })  
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        TIPO_Nombre: Yup.string().max(25).required('Campo requerido!'),
      })}
      onSubmit={async (values, actions) => {
        await sendMtdPago({
          variables: {
            data: {
              TIPO_Nombre: values.TIPO_Nombre,
              TIPO_Activo: values.TIPO_Activo !== false ? 1 : 0,
              TIPO_Id: values.TIPO_Id,
              TIPO_ByModify: itemSession.USR_Id,
            },
          }
        })
        actions.resetForm({
          values: {
            TIPO_Nombre: '',

          },
          // you can also set the other form states here
        });
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              title="Editar servicios"
              style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
              action={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  {/* <Button
                    color="inherit"
                    size="large"
                    onClick={GoToNew}
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button> */}
                  {/* </RouterLink> */}

                  <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
                </div>
              }
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Servicio"
                    name="TIPO_Nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.TIPO_Nombre}
                  />
                  {errors.TIPO_Nombre && touched.TIPO_Nombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.TIPO_Nombre}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="subtitle2">
                      Activa
                    </Typography>
                    <Switch size="small" name="TIPO_Activo" checked={values.TIPO_Activo} onChange={handleChange} />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Guardar
              </Button>
            </Box>
          </Card>
          <Snack open={open} handleClose={handleClose} message="¡ Servicio editado !" />
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;