import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
  useTheme,
  Tooltip
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Formik } from 'formik';
import { schemaUsuarioPassword, schemaUsuarioSinPassword } from '../../../shared/validators/Forms';
import { EDIT_USUARIO, SUC_USER, GET_LVL_USER } from '../../../graphql/Usuarios';
import { GET_DATA_SUCURSAL } from '../../../graphql/Selects';
import { useMutation, useQuery } from '@apollo/react-hooks';
import SaveIcon from '@material-ui/icons/Save';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingSpiner from '../../../components/LoadingSpiner';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    minWidth: 490,
    maxWidth: 960,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  let arrayIds = []
  if (personName.length > 0) {
    personName.map(item => {
      arrayIds.push(item.value)
      return item
    })
  }
  return {
    fontWeight:
      arrayIds.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  //Para abrir el mensaje de exito
  const [open, setOpen] = useState(false);
  //Contiene los errores del formulario
  const [errorForm, setErrorForm] = useState();
  //Constante para manejar el estado del loading
  const [loading, setLoading] = useState(false);
  //Valor del select de sucursales
  const [sucursalesSelect, setSucursalesSelect] = useState([]);
  //Contenido del select de sucursales
  const [datasSucursal, setDatasSucursal] = useState([]);
  //Copia de los datos que vienen de bd para eliminar sucursales
  const [copySucursalSelect, setCopySucursalSelect] = useState([]);
  //Constante para alojar los niveles de usuario que vienen del backend
  const [levelSelect, setLevelSelect] = useState([]);
  let auth = JSON.parse(localStorage.getItem('sesTime'));
  //Obtener el arreglo de la sucursal seleccionada
  const handleChangeSelect = (event) => {
    //Guardo la variable en una mas facil de acceder
    let data = event.target.value
    //Recorro los datos entrantes
    data.map(response => {
      //Verifico el tipo de dato que contiene el array
      if (typeof response === "number") {
        if (sucursalesSelect.find((item, i) => item.value === response)) {
          //Este apartado se cumple solo cuando se quiere eliminar una sucursal seleccionada
          let sinElNew = data.filter((value) => { return typeof value !== "number" })
          let borrado = sinElNew.filter((item) => { return item.value !== response })
          setSucursalesSelect(borrado)
        } else {
          //UNa vez verificado se busca el valor en el objeto para asignarlo a la variable
          let values = datasSucursal.find(item => item.value === response)
          setSucursalesSelect([...sucursalesSelect, values]);
        }
      }
      return response
    })
  };

  const initialValues = {
    USR_NombreCom: location.state.USR_NombreCom,
    USR_Email: location.state.USR_Email,
    USR_Telefono: location.state.USR_Telefono,
    USR_Direccion: location.state.USR_Direccion,
    USR_Descripcion: location.state.USR_UGP_ID,
    USR_Id: location.state.USR_Id,
    USR_Active: location.state.USR_Active ? true : false,
    USR_Password: '',
    USR_RePassword: '',
    USR_Disabled: location.state.USR_Disabled ? true : false
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  //query para llenar selector
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  //Query para obtener el lvl de usuario
  const { data: dataLvlUser, loading: loading1, error: error1 } = useQuery(GET_LVL_USER);
  //Query para obtener las sucursales asignadas al usuario
  const { data: getSucursales, loading: loading3, error: error3 } = useQuery(SUC_USER, {
    fetchPolicy: "no-cache",
    variables: { data: { USR_Id: location.state.USR_Id } }
  })
  const [sendUser] = useMutation(EDIT_USUARIO, {
    onCompleted: data => {
      if (data) {
        setOpen(true)
        setTimeout(() => {
          history.push({
            pathname: '/app/usuarios',
            state: { isRefetch: true },
          });
        }, 1000)
      }
    }
  })

  const sendData = async (values) => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm({})
    //Creo el objeto a validar
    let data = {
      USR_Id: values.USR_Id,
      USR_NombreCom: values.USR_NombreCom,
      USR_Email: values.USR_Email,
      USR_Telefono: values.USR_Telefono,
      USR_Direccion: values.USR_Direccion,
      USR_Descripcion: values.USR_Descripcion,
      USR_Password: values.USR_Password,
      USR_RePassword: values.USR_RePassword,
      USR_Active: values.USR_Active ? true : false,
      USR_Disabled: values.USR_Disabled ? true : false,
      USR_ModifiedBy: auth.USR_Id,
      USR_Sucursales: sucursalesSelect,
      USR_SucursalesCopy: copySucursalSelect
    }
    //Valido con promesa
    //En este if se selecciona cual de los dos schemas de validacion se utilizaran en el envio
    if (values.USR_Password !== '') {
      schemaUsuarioPassword.validate(data)
        //Si es valido se envia el formulario
        .then(async (res) => {
          await sendUser({ variables: { data } })
        })
        //Si no se generan los errores. Err solo devuelve 1 error a la vez
        .catch((err) => {
          setLoading(false);
          let object = {}
          object[err.path] = err.errors
          setErrorForm(object)
        })
    } else {
      schemaUsuarioSinPassword.validate(data)
        //Si es valido se envia el formulario
        .then(async (res) => {
          await sendUser({ variables: { data } })
        })
        //Si no se generan los errores. Err solo devuelve 1 error a la vez
        .catch((err) => {
          setLoading(false);
          let object = {}
          object[err.path] = err.errors
          setErrorForm(object)
        })

    }
  }

  useEffect(() => {
    setLoading(true)
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = []
      dataSucursal.GetDataSucursal.map(item => {
        return thing.push({ label: item.SUC_Nombre, value: item.SUC_Id })
      })
      setDatasSucursal(thing)
      setLoading(false)
    }
    //Este es el apartado que se encarga de armar el contenido del select
    if (getSucursales && loading3 === false && error3 === undefined){
      let thing = []
      if(getSucursales.GetSucursalUser.length > 0) {
        getSucursales.GetSucursalUser.map(item => {
          if(item.SSU_Id != null) {
            //con el .find se obtienen solo los que cumplan la validacion
            let finded = dataSucursal.GetDataSucursal.find((itemF, i) => itemF.SUC_Id === item.SSU_SUC_Id)
            finded['SSU_Id'] = item.SSU_Id
            return thing.push({ label: finded.SUC_Nombre, value: finded.SUC_Id, SSU_Id: item.SSU_Id })

          }
          return item
        })
        setSucursalesSelect(thing)
        setCopySucursalSelect(thing)
      }
    }
    if (dataLvlUser && loading1 === false && error1 === undefined) {
      let thing = []
      dataLvlUser.getLvlUser.map(item => {
        return thing.push({ label: item.UGP_Nombre, value: item.UGP_ID })
      })
      setLevelSelect(thing)
      setLoading(false)
    }
  }, [dataSucursal,
    loading4,
    error4,
    getSucursales,
    loading3,
    error3,
    dataLvlUser,
    loading1,
    error1,
  ])

  const goBackButton = () => {
    // history.push('services')
    history.push({
      pathname: 'usuarios',
      state: { isRefetch: true }
    })  
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => sendData(values)}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              title={`Editando Usuario: ${location.state.USR_Id}`}
              style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  {/* <Button                                        
                    color="inherit"
                    size="large"             
                    onClick={ GoToNew}       
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button> */}
                {/* </RouterLink> */}

                <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </div>
            }
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >

                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nombre Completo"
                    name="USR_NombreCom"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_NombreCom}                    
                  />
                  {errorForm && touched.USR_NombreCom ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_NombreCom}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Email"
                    name="USR_Email"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_Email}                    
                  />
                  {errorForm && touched.USR_Email ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Email}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Teléfono"
                    name="USR_Telefono"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_Telefono}                   
                  />
                  {errorForm && touched.USR_Telefono ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Telefono}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Dirección"
                    name="USR_Direccion"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_Direccion}                    
                  />
                  {errorForm && touched.USR_Direccion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Direccion}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Rol"
                    name="USR_Descripcion"
                    onChange={handleChange}
                    required
                    select
                    value={values.USR_Descripcion}                   
                  >
                    {!!levelSelect && (levelSelect.map((option) => (
                      <MenuItem key={`rol_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Login"
                    name="USR_Id"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_Id}                    
                  />
                  {errorForm && touched.USR_Id ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Id}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label" required>Sucursales</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      required
                      value={sucursalesSelect}
                      onChange={handleChangeSelect}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((item) => (
                            <Chip key={item.value} label={item.label} className={classes.chip} />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {!!datasSucursal && (datasSucursal.map((item) => (
                        <MenuItem key={item.value} value={item.value} style={getStyles(item.value, sucursalesSelect, theme)}>
                          {item.label}
                        </MenuItem>
                      )))}
                    </Select>
                  </FormControl>
                  {errorForm ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Sucursales}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox name="USR_Active" value={values.USR_Active} checked={values.USR_Active} onChange={handleChange} />
                    }
                    label="Activo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox name="USR_Disabled" onChange={handleChange} checked={values.USR_Disabled} value={values.USR_Disabled} />
                    }
                    label="Bloqueado"
                  />
                </Grid>

              </Grid>
            </CardContent>
            <Divider />

          </Card>
          <Box mt={3}>
            <Card>
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Contraseña"
                      name="USR_Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      value={values.USR_Password}                      
                    />
                    {errorForm && touched.USR_Password ? (
                      <Typography
                        color="error"
                        variant="subtitle2">
                        {errorForm.USR_Password}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Confirmar Contraseña"
                      name="USR_RePassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      value={values.USR_RePassword}            
                    />
                    {errorForm && touched.USR_RePassword ? (
                      <Typography
                        color="error"
                        variant="subtitle2">
                        {errorForm.USR_RePassword}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}

                >
                  Modificar
                  </Button>
              </Box>
            </Card>
          </Box>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha editado con exito el usuario!
            </Alert>
          </Snackbar>
          <LoadingSpiner estado={loading} />
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;