import 'react-perfect-scrollbar/dist/css/styles.css';
import React,{useEffect} from 'react';
// import { useRoutes } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
// import 'src/mixins/chartjs';
import theme from 'src/theme';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';



import ReactGA from 'react-ga';

// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';

import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import DashboardView from './views/reports/DashboardView';
import NotFoundView from './views/errors/NotFoundView';

import LoginView from './views/auth/LoginView';
import NewUser from './views/auth/newUser';

import ServicesView from './views/services/index';
import NewService from './views/services/FormServices/index';
import EditService from './views/services/EditServices/index';
import VerServicio from './views/services/VerServicio/index';

import Gastos from './views/gastos/index';
import NewGastos from "./views/gastos/FormGastos/index";
import EditGastos from "./views/gastos/EditGastos/index";
import VerGasto from './views/gastos/VerGastos/index';

import Depositos from './views/depositos/index';
import NewDepositos from "./views/depositos/FormDeposito/index";
import EditDeposito from "./views/depositos/EditDeposito/index";
import VerDeposito from './views/depositos/VerDeposito/index';

import Usuarios from './views/usuarios/index';
import NewUsuarios from './views/usuarios/FormUsuarios/index';
import EditUsuarios from './views/usuarios/EditUsuarios/index';
import VerUsuarios from './views/usuarios/VerUsuarios/index';

import MensajeWeb from './views/CountMenWeb/index';
import NewMensajeWeb from './views/CountMenWeb/FormCount/index';
import EditMensajeWeb from './views/CountMenWeb/EditCount/index';

import Inventario from './views/inventario/index';
import NewInventario from './views/inventario/FormInventarios/index';
import EditInventario from './views/inventario/EditInventarios/index';
import VerInventario from './views/inventario/VerInventarios/index';

// import ReportList from './views/reports/ReportList';
import ReportList from './views/reports/index';
import ReportSaleDay from './views/reports/ReportSaleDay';
import ReportTurAdduser from './views/reports/ReportTurAdduser';
import ReportRendicion from './views/reports/ReportRendicion';
import ReportPago from './views/reports/ReportPago';
import ReportGastos from './views/reports/ReportGastos';

import ReportTurAdduserDay from './views/reports/ReportTurAdduserDay';
import ReportClient from './views/reports/ReportClient';
import ReportTopServices from './views/reports/ReportTopServices';
import ReportLastOrder from './views/reports/ReportLastOrder';


//Bancos
import Mtdpago from './views/config/mtdpago/index';
import NewMtdpago from './views/config/mtdpago/FormMtdPago/index';
import VerMtdpago from './views/config/mtdpago/VerMtdPago/index';
import EditMtdpago from './views/config/mtdpago/EditMtdPago/index';

import Sucursal from './views/config/sucursal/index';
import NewSucursal from './views/config/sucursal/FormSucursal/index';
import VerSucursal from './views/config/sucursal/VerSucursal/index';
import EditSucursal from './views/config/sucursal/EditSucursal/index';

import AddServicios from './views/config/servicios/index';
import NewAddServicios from './views/config/servicios/FormServicios/index';
import VerAddServicios from './views/config/servicios/VerServicios/index';
import EditAddServicios from './views/config/servicios/EditServicios/index';

import Parametros from './views/config/parametros/index';
// import NewAddServicios from './views/config/servicios/FormServicios/index';
import VerParametro from './views/config/parametros/VerParametro/index';
import EditParametros from './views/config/parametros/EditParametro/index';

import Tax from './views/config/tax/index';
import NewTax from './views/config/tax/FormTax/index';
import VerTax from './views/config/tax/VerTax/index';
import EditTax from './views/config/tax/EditTax/index';

import Barrios from './views/config/barrios/index';
// import NewTax from './views/config/tax/FormTax/index';
// import VerTax from './views/config/tax/VerTax/index';
// import EditTax from './views/config/tax/EditTax/index';

import Legajo from './views/legajo/index'
import NewLegajo from './views/legajo/FormLegajo/index';
import VerLegajo from './views/legajo/VerLegajo/index';
// import SettingsView from 'src/views/settings/SettingsView';
// import AccountView from 'src/views/account/AccountView';
// import CustomerListView from 'src/views/customer/CustomerListView';
// import ProductListView from 'src/views/product/ProductListView';

import Configuracion from './views/config/index';

const client = new ApolloClient({
   uri: "https://turnosmam.unelca.com/graphql"
  // uri: "http://localhost:4000/graphql"
  
})

ReactGA.initialize("G-R24RPGT5L4");

const App = () => {
  // const [open, setOpen] = React.useState(true);
  // const routing = useRoutes(routes);
  
  useEffect(() => {
    // Forzar recarga de la página ignorando la caché
    // window.localStorage.clear();
    // window.localStorage.clear();

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <GlobalStyles />
          <Switch>
            <Route exact path="/">
              <MainLayout>
                <LoginView />
              </MainLayout>
            </Route>
            <Route exact path="/newUser">
              <MainLayout>
                <NewUser />
              </MainLayout>
            </Route>
            <Route exact path="/app/dashboard">
              <DashboardLayout viewName={'Dashboard'}>
                <DashboardView />
              </DashboardLayout>
            </Route>
            
            <Route path="/app/services">
              <DashboardLayout viewName={'Services'}>
                <ServicesView />
              </DashboardLayout>
            </Route>
            <Route path="/app/newService">
              <DashboardLayout viewName={'NewServices'}>
                <NewService />
              </DashboardLayout>
            </Route>
            <Route path="/app/editService">
              <DashboardLayout viewName={'EditServices'}>
                <EditService />
              </DashboardLayout>
            </Route>
            <Route path="/app/verServicio">
              <DashboardLayout viewName={'VerServicios'}>
                <VerServicio />
              </DashboardLayout>
            </Route>
            
            <Route path="/app/gastos">
              <DashboardLayout viewName={'Gastos'}>
                <Gastos />
              </DashboardLayout>
            </Route>
            <Route path="/app/newGasto">
              <DashboardLayout viewName={'NewGastos'}>
                <NewGastos />
              </DashboardLayout>
            </Route>
            <Route path="/app/editGasto">
              <DashboardLayout viewName={'EditGastos'}>
                <EditGastos />
              </DashboardLayout>
            </Route>
            <Route path="/app/verGasto">
              <DashboardLayout viewName={'VerGastos'}>
                <VerGasto />
              </DashboardLayout>
            </Route>
            <Route path="/app/depositos">
              <DashboardLayout viewName={'Depositos'}>
                <Depositos />
              </DashboardLayout>
            </Route>
            <Route path="/app/newDeposito">
              <DashboardLayout viewName={'NewDeposito'}>
                <NewDepositos />
              </DashboardLayout>
            </Route>
            <Route path="/app/editDeposito">
              <DashboardLayout viewName={'EditDeposito'}>
                <EditDeposito />
              </DashboardLayout>
            </Route>
            <Route path="/app/verDeposito">
              <DashboardLayout viewName={'VerDepositos'}>
                <VerDeposito />
              </DashboardLayout>
            </Route>
            <Route path="/app/usuarios">
              <DashboardLayout viewName={'Usuarios'}>
                <Usuarios />
              </DashboardLayout>
            </Route>
            <Route path="/app/newUsuario">
              <DashboardLayout viewName={'NewUsuarios'}>
                <NewUsuarios />
              </DashboardLayout>
            </Route>
            <Route path="/app/EditUsuario">
              <DashboardLayout viewName={'EditUsuarios'}>
                <EditUsuarios />
              </DashboardLayout>
            </Route>
            <Route path="/app/verUsuario">
              <DashboardLayout viewName={'VerUsuarios'}>
                <VerUsuarios />
              </DashboardLayout>
            </Route>
            {/*Inicio de Mensajeria  */}
            <Route path="/app/MensajeWeb">
              <DashboardLayout viewName={'MensajeWeb'}>
                <MensajeWeb />
              </DashboardLayout>
            </Route>
            <Route path="/app/NewMensajeWeb">
              <DashboardLayout viewName={'NewMensajeWeb'}>
                <NewMensajeWeb />
              </DashboardLayout>
            </Route>
            <Route path="/app/EditMensajeWeb">
              <DashboardLayout viewName={'EditMensajeWeb'}>
                <EditMensajeWeb />
              </DashboardLayout>
            </Route>

            {/*Fin de Mensajeria  */}

            <Route path="/app/inventario">
              <DashboardLayout viewName={'Inventario'}>
                <Inventario />
              </DashboardLayout>
            </Route>
            <Route path="/app/NewInventario">
              <DashboardLayout viewName={'NewInventario'}>
                <NewInventario />
              </DashboardLayout>
            </Route>
            <Route path="/app/VerInventario">
              <DashboardLayout viewName={'Inventario'}>
                <VerInventario />
              </DashboardLayout>
            </Route>
            <Route path="/app/editInventario">
              <DashboardLayout viewName={'EditInventario'}>
                <EditInventario />
              </DashboardLayout>
            </Route>
            <Route exact path="/app/reports">
              <DashboardLayout viewName={'ListReport'}>
                <ReportList />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportSaleDay">
              <DashboardLayout viewName={'DataTable'}>
                <ReportSaleDay />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportTurAdduser">
              <DashboardLayout viewName={'DataTable'}>
                <ReportTurAdduser />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportRendicion">
              <DashboardLayout viewName={'DataTable'}>
                <ReportRendicion />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportPago">
              <DashboardLayout viewName={'DataTable'}>
                <ReportPago />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportGastos">
              <DashboardLayout viewName={'DataTable'}>
                <ReportGastos />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportTurAdduserDay">
              <DashboardLayout viewName={'DataTable'}>
                <ReportTurAdduserDay />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportClient">
              <DashboardLayout viewName={'DataTable'}>
                <ReportClient />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportLastOrder">
              <DashboardLayout viewName={'DataTable'}>
                <ReportLastOrder />
              </DashboardLayout>
            </Route>
            <Route path="/app/reports/ReportTopServices">
              <DashboardLayout viewName={'DataTable'}>
                <ReportTopServices />
              </DashboardLayout>
            </Route>
            {/*Inicio Bancos */}
            <Route path="/app/MtdPago">
              <DashboardLayout viewName={'mtdpago'}>
                <Mtdpago />
              </DashboardLayout>
            </Route>
            <Route path="/app/NewMtdPago">
              <DashboardLayout viewName={'NewMtdPago'}>
                <NewMtdpago />
              </DashboardLayout>
            </Route>
            <Route path="/app/VerMtdPago">
              <DashboardLayout viewName={'VerMtdPago'}>
                <VerMtdpago />
              </DashboardLayout>
            </Route>
            <Route path="/app/EditMtdPago">
              <DashboardLayout viewName={'editMtdPago'}>
                <EditMtdpago />
              </DashboardLayout>
            </Route>
            {/* Fin de Bancos */}

            {/*Inicio Sucursal */}
            <Route path="/app/Sucursal">
              <DashboardLayout viewName={'Sucursal'}>
                <Sucursal />
              </DashboardLayout>
            </Route>
            <Route path="/app/NewSucursal">
              <DashboardLayout viewName={'NewSucursal'}>
                <NewSucursal />
              </DashboardLayout>
            </Route>
            <Route path="/app/VerSucursal">
              <DashboardLayout viewName={'VerSucursal'}>
                <VerSucursal />
              </DashboardLayout>
            </Route>
            <Route path="/app/EditSucursal">
              <DashboardLayout viewName={'EditSucursal'}>
                <EditSucursal />
              </DashboardLayout>
            </Route>
            {/*Fin Sucursal */}

            {/*Inicio Servicios */}
            <Route path="/app/AddServicios">
              <DashboardLayout viewName={'AddServicios'}>
                <AddServicios />
              </DashboardLayout>
            </Route>
            <Route path="/app/NewAddServicios">
              <DashboardLayout viewName={'NewAddServicios'}>
                <NewAddServicios />
              </DashboardLayout>
            </Route>
            <Route path="/app/VerAddServicios">
              <DashboardLayout viewName={'VerAddServicios'}>
                <VerAddServicios />
              </DashboardLayout>
            </Route>
            <Route path="/app/EditAddServicios">
              <DashboardLayout viewName={'EditAddServicios'}>
                <EditAddServicios />
              </DashboardLayout>
            </Route>
            {/*Fin Servicios */}

            <Route path="/app/Parametros">
              <DashboardLayout viewName={'Parametros'}>
                <Parametros />
              </DashboardLayout>
            </Route>
            <Route path="/app/VerParametros">
              <DashboardLayout viewName={'VerParametros'}>
                <VerParametro />
              </DashboardLayout>
            </Route>
            <Route path="/app/EditParametros">
              <DashboardLayout viewName={'EditParametros'}>
                <EditParametros />
              </DashboardLayout>
            </Route>
            <Route path="/app/Tax">
              <DashboardLayout viewName={'Tax'}>
                <Tax />
              </DashboardLayout>
            </Route>
            <Route path="/app/NewTax">
              <DashboardLayout viewName={'AddTax'}>
                <NewTax />
              </DashboardLayout>
            </Route>
            <Route path="/app/VerTax">
              <DashboardLayout viewName={'VerTax'}>
                <VerTax />
              </DashboardLayout>
            </Route>
            <Route path="/app/EditTax">
              <DashboardLayout viewName={'EditTax'}>
                <EditTax />
              </DashboardLayout>
            </Route>
            <Route path="/app/Barrio">
              <DashboardLayout viewName={'Barrios'}>
                <Barrios />
              </DashboardLayout>
            </Route>
            <Route path="/app/legajo">
              <DashboardLayout viewName={'Legajo'}>
                <Legajo />
              </DashboardLayout>
            </Route>
            <Route path="/app/newlegajo">
              <DashboardLayout viewName={'addLegajo'}>
                <NewLegajo />
              </DashboardLayout>
            </Route>
            <Route path="/app/verlegajo">
              <DashboardLayout viewName={'verLegajo'}>
                <VerLegajo />
              </DashboardLayout>
            </Route>
            <Route path="/app/Config">
              <DashboardLayout viewName={'Config'}>
                <Configuracion />
              </DashboardLayout>
            </Route>
            <Route path="*">
              <NotFoundView />
            </Route>            
          </Switch>
        </Router>     
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
