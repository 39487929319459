import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import { GET_MTD_PAGO_BANK } from "../../../graphql/Mtdpago";
import { useHistory, useLocation } from 'react-router-dom';
import { Visibility, Edit } from '@material-ui/icons';
import { RowsxList } from "../../../Commons"

import AddIcon from '@material-ui/icons/Add';

const columns = [
  { field: '', headerName: '', width: 130 },
  { field: '', headerName: '', width: 130 },
  { field: 'Nombre', headerName: 'Nombre', width: 160 },
  // { field: 'Comisión', headerName: 'Comisión', width: 160 },

];

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  }
}));

const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const { loading: loading_Services, error: error_Services, data: servicesData, refetch } = useQuery(GET_MTD_PAGO_BANK)
  const location = useLocation();

  const handleClick = (tipo, data) => {
    if (tipo === 'edit') {
      history.push({
        pathname: 'editMtdPago',
        state: data
      })
    } else {
      history.push({
        pathname: 'verMtdPago',
        state: data
      })
    }
  }

  useEffect(() => {
    let thing = []
    if (servicesData && loading_Services === false && error_Services === undefined) {
      servicesData.GetMtdPagoBank.map(item => {
        item['id'] = item.CTA_Id;
        item['Nombre'] = item.CTA_Nombre;
        // item['Comisión'] = item.MTD_Comision; 
        return thing.push(item)
      })
      setDataTable(thing);
    }
    if (location) {
      if (location.state != null) {
        if (location.state.isRefetch)
          refetch()
      }
    }
  }, [servicesData, error_Services, loading_Services, location, refetch])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const GoToNew = () => {
    history.push('/app/NewMtdPago')}
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Metodo de Pago"
        style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
        action={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
              <Button
                color="inherit"
                size="large"
                onClick={GoToNew}
                startIcon={<AddIcon />}
              >
                Nuevo
              </Button>
            {/* </RouterLink> */}

            {/* <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip> */}
          </div>
        }
      />
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                <TableCell className={classes.head} >
                  Ver
                </TableCell>
                <TableCell className={classes.head} >
                  Modificar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, i) => {
                return (
                  <TableRow style={i % 2 ? { background: "#f4f6f8" } : { background: "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Visibility onClick={() => handleClick('ver', order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit', order)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default DataTable;