import { gql } from 'apollo-boost';


//query

export const GET_DEPOSITO = gql`
  query GetDepositosDashboard{
    GetDepositosDashboard{
      SERV_Sucursal
      CTA_Nombre
      CTA_Tipo
      SERV_Estado
      SERV_Delete
      SERV_SID_Importe
      SERV_SID_ImporteNeto
      SERV_SID_MtdPago
    }
  }
`;

export const GET_GASTO = gql`
  query GetGastosDashboard{
    GetGastosDashboard{
      GAST_Id
      GAST_Fecha
      GAST_Importe
      SUC_Nombre
      GAST_Sucursal
      GAST_Mtdpago      
    }
  }
`;

export const GET_SERVICIO = gql`
  query GetServicioDashboard{
    GetServicioDashboard{
      SERV_Sucursal
      CTA_Nombre
      CTA_Tipo
      SERV_Estado
      SERV_Delete
      SERV_SID_Importe
      SERV_SID_ImporteNeto
      SERV_SID_MtdPago
    }
  }
`;

export const GET_SERVICIO3D = gql`
  query GetServicio3dDashboard{
    GetServicio3dDashboard{
      SUC_Nombre
      Turnos
    }
  }
`;

export const GET_SERVICIOADD = gql`
  query GetServicioADDDashboard{
    GetServicioADDDashboard{
      SUC_Nombre
      Turnos
    }
  }
`;

export const GET_SERVICIOS_TURNOSREALIZADO = gql`
  query GetServicioTurnosRealizados{
    GetServicioTurnosRealizados{
      SUC_Nombre
      SucursalId
      TotalServicios
      SERV_Estado
      Pendiente
      Confirmado
      Eliminado
    }
  }  
`;

export const GET_VENTAS_DIARIAS = gql`
  query GetServicioVentasDiarias{
    GetServicioVentasDiarias{
      SERV_Sucursal
      CTA_Nombre
      SERV_SID_Importe
      SERV_SID_ImporteNeto
      SUC_Nombre
    }
  }  
`;

export const GET_LAST_ORDER = gql `
  query GetLastOrder{
    GetLastOrder{
      SERV_Id
      SUC_Nombre
      SERV_Fecha
      STATUS_Nombre    
    }
  }
`
export const GETALLSELLSMONTH = gql `
  query GetAllSellMonth{
    GetAllSellMonth{
      SERV_Sucursal
      SUC_Nombre
      Total
    }
  }
` 
export const GETALLSERVMONTH = gql `
  query GetAllServMonth{
    GetAllServMonth{      
      SUC_Nombre
      TIPO_Nombre
      Cantidad
    }
  }
` 