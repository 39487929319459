import React from 'react';
import { Link as RouterLink,useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  // Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
// import { LogOut as LogOutIcon} from 'react-feather';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
// import LogoutIcon from '@mui/icons-material/Logout';
import Logo from 'src/components/Logo';
import {Typography} from '@material-ui/core';
const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  buttonExit: {
    color: 'white', 
    // icon: LogOutIcon    
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  // const [notifications] = useState([]);
  const history = useHistory();

  const redireccionar = () => {
    localStorage.removeItem('sesTime');
    localStorage.removeItem('sucursalesUser');
    localStorage.removeItem('sessionStartTime');
    history.push('/')
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink style={{ backgroundColor: '#fff', borderRadius: '20px', width: '38px', alignItems: 'center', height: '38px', display: 'flex', marginRight: '16px'  }} to="/app/dashboard">
          <Logo />
          
        </RouterLink>
        <Typography variant="h4" color="inherit">
           Sistema de Gestión de Turnos - Unelca
        </Typography>
        <Box flexGrow={3} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <RouterLink to='#' className={classes.buttonExit} onClick={redireccionar}>
            <IconButton color="inherit">
              <InputIcon />
            </IconButton>
          </RouterLink>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
