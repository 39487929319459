// import React, { useState, useEffect } from 'react';
// import clsx from 'clsx';
// import PropTypes from 'prop-types';
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   CardHeader,
//   Divider,
//   Grid,
//   TextField,
//   Typography,
//   makeStyles
// } from '@material-ui/core';
// import SaveIcon from '@material-ui/icons/Save';
// import MenuItem from '@material-ui/core/MenuItem';
// import { Formik } from 'formik';
// import {
//   GET_DATA_SUCURSAL,
//   GET_MTD_PAGO_GASTO_NEW
// } from '../../../graphql/Selects';
// import { NEW_GASTO } from '../../../graphql/Gasto';
// import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
// import { useHistory } from 'react-router-dom';

// import ImageUploader from 'react-images-upload';
// import { MapeoSucursales } from '../../../Commons';
// import { schemaGastos } from '../../../shared/validators/Forms';
// import LoadingSpiner from '../../../components/LoadingSpiner';
// import Snack from 'src/utils/Common';

// // function Alert(props) {
// //   return <MuiAlert elevation={6} variant="filled" {...props} />;
// // }
// const useStyles = makeStyles(() => ({
//   root: {},
//   buttonStyle: {
//     marginTop: '2%',
//     marginRight: '1%'
//   },
//   headerStyle: {
//     display: 'flex',
//     alignItems: 'baseline',
//     justifyContent: 'space-between'
//   }
// }));

// const ServiceForm = ({ className, ...rest }) => {
//   const classes = useStyles();
//   // const fileInput = createRef();
//   const history = useHistory();
//   const [loading, setLoading] = useState(false);
//   const [errorForm, setErrorForm] = useState({});
//   const [img, setImg] = useState('');
//   const itemSession = JSON.parse(localStorage.getItem('sesTime'));
//   const [open, setOpen] = useState(false);
//   const [suc, setSucursal] = useState();

//   // Mantén el estado para el Snack personalizado
//   const [snackOpen, setSnackOpen] = useState(false);
//   const [snackMessage, setSnackMessage] = useState('');
//   const [snackSeverity, setSnackSeverity] = useState('success');

//   // Cambia el nombre de la función para cerrar el Snack
//   const handleCloseSnack = () => {
//     setSnackOpen(false);
//   };

//   const goBackButton = () => {
//     history.push('gastos');
//   };

//   const onDrop = (pictureFiles, pictureDataURLs) => {
//     setImg(pictureDataURLs[0]);
//   };

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }

//     setOpen(false);
//   };

//   const handleChangeSuc = e => {
//     if (e.target.value !== '') {
//       setSucursal(e.target.value);
//       loadMtdPago({ variables: { data: { Sucursal: e.target.value } } });
//     }
//   };

//   const [datasSucursal, setDatasSucursal] = useState([]);
//   const [datasMtdPago, setDatasMtdPago] = useState([]);
//   const [
//     loadMtdPago,
//     { data: dataMtdPago, loading: loading3, error: error3 }
//   ] = useLazyQuery(GET_MTD_PAGO_GASTO_NEW, {
//     fetchPolicy: 'no-cache'
//   });
//   const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(
//     GET_DATA_SUCURSAL
//   );
//   const [sendGasto] = useMutation(NEW_GASTO, {
//     onCompleted: data => {
//       if (data) {
//         setSnackMessage('Se ha creado con éxito el gasto!');
//         setSnackSeverity('success');
//         setSnackOpen(true);
//         // setOpen(true);
//       }
//       // <Redirect to='/app/service' />
//       setTimeout(() => {
//         history.push({
//           pathname: '/app/gastos',
//           state: { isRefetch: true }
//         });
//       }, 1000);
//     }
//   });
//   //Funcion para enviar datos al backend
//   const sendData = async values => {
//     setLoading(true);
//     //Limpio los errores anteriores
//     setErrorForm({});
//     //Creo el objeto a validar
//     let data = {
//       GAST_NumFact: values.GAST_NumFact,
//       GAST_Fecha: values.GAST_Fecha,
//       GAST_Descripcion: values.GAST_Descripcion,
//       GAST_Importe: values.GAST_importe,
//       GAST_Sucursal: suc,
//       GAST_User: itemSession.USR_Id,
//       GAST_Mtdpago: values.GAST_Mtdpago,
//       GAST_Recibo: img
//     };
//     //Valido con promesa
//     schemaGastos
//       .validate(data)
//       //Si es valido se envia el formulario
//       .then(async res => {
//         await sendGasto({ variables: { data } });
//       })
//       //Si no se generan los errores. Err solo devuelve 1 error a la vez
//       .catch(err => {
//         setLoading(false);
//         let object = {};
//         object[err.path] = err.errors;
//         setErrorForm(object);
//       });
//   };
//   useEffect(() => {
//     if (dataSucursal && loading4 === false && error4 === undefined) {
//       let thing = MapeoSucursales(dataSucursal);
//       setDatasSucursal(thing);
//     }
//     if (dataMtdPago && loading3 === false && error3 === undefined) {
//       let thing = [];
//       dataMtdPago.GetDatamtdpagoDeposito1.map(item => {
//         return thing.push({ label: item.CTA_Nombre, value: item.CTA_Id });
//       });
//       setDatasMtdPago(thing);
//     }
//   }, [dataSucursal, loading4, error4, dataMtdPago, loading3, error3]);
//   return (
//     <Formik
//       initialValues={{
//         GAST_Fecha: new Date().toISOString().substring(0, 10),
//         GAST_Descripcion: '',
//         GAST_importe: '',
//         GAST_Sucursal: '',
//         GAST_recibo: '',
//         GAST_NumFact: '',
//         GAST_Mtdpago: ''
//       }}
//       onSubmit={async (values, actions) => {
//         sendData(values);
//       }}
//     >
//       {({ values, touched, handleChange, handleBlur, handleSubmit }) => (
//         <form
//           {...rest}
//           className={clsx(classes.root, className)}
//           onSubmit={handleSubmit}
//         >
//           <Card>
//             <Grid container spacing={3}>
//               <Grid item className={classes.headerStyle} md={6} xs={12}>
//                 <CardHeader subheader="Genera un nuevo gasto" title="Gastos" />
//                 <Button
//                   className={classes.buttonStyle}
//                   type="submit"
//                   variant="contained"
//                   color="primary"
//                   size="large"
//                   onClick={goBackButton}
//                 >
//                   Atras
//                 </Button>
//               </Grid>
//             </Grid>
//             <Divider />
//             <CardContent>
//               <Grid container spacing={3}>
//                 <Grid item md={6} xs={12}>
//                   <TextField
//                     fullWidth
//                     required
//                     label="Fecha de servicio"
//                     type="date"
//                     name="GAST_Fecha"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     value={values.GAST_Fecha}
//                     InputLabelProps={{
//                       shrink: true
//                     }}
//                   />
//                   {errorForm.GAST_Fecha && touched.GAST_Fecha ? (
//                     <Typography color="error" variant="subtitle2">
//                       {errorForm.GAST_Fecha}
//                     </Typography>
//                   ) : null}
//                 </Grid>
//                 <Grid item md={6} xs={12}>
//                   <TextField
//                     fullWidth
//                     required
//                     label="Gasto en"
//                     name="GAST_Descripcion"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     type="text"
//                     value={values.GAST_Descripcion}
//                   />
//                   {errorForm.GAST_Descripcion && touched.GAST_Descripcion ? (
//                     <Typography color="error" variant="subtitle2">
//                       {errorForm.GAST_Descripcion}
//                     </Typography>
//                   ) : null}
//                 </Grid>
//                 <Grid item md={6} xs={12}>
//                   <TextField
//                     fullWidth
//                     label="Importe"
//                     name="GAST_importe"
//                     required
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     type="number"
//                     value={values.GAST_importe}
//                   />
//                   {errorForm.GAST_importe && touched.GAST_importe ? (
//                     <Typography color="error" variant="subtitle2">
//                       {errorForm.GAST_importe}
//                     </Typography>
//                   ) : null}
//                 </Grid>
//                 <Grid item md={6} xs={12}>
//                   <TextField
//                     fullWidth
//                     required
//                     label="Num factura"
//                     name="GAST_NumFact"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     type="text"
//                     value={values.GAST_NumFact}
//                   />
//                   {errorForm.GAST_NumFact && touched.GAST_NumFact ? (
//                     <Typography color="error" variant="subtitle2">
//                       {errorForm.GAST_NumFact}
//                     </Typography>
//                   ) : null}
//                 </Grid>
//                 <Grid item md={6} xs={12}>
//                   <TextField
//                     fullWidth
//                     required
//                     select
//                     name="GAST_Sucursal"
//                     label="Sucursal"
//                     onBlur={handleBlur}
//                     value={suc}
//                     onChange={handleChangeSuc}
//                   >
//                     {!!datasSucursal &&
//                       datasSucursal.map(option => (
//                         <MenuItem
//                           key={`sucursal_${option.value}`}
//                           value={option.value}
//                         >
//                           {option.label}
//                         </MenuItem>
//                       ))}
//                   </TextField>
//                   {errorForm.GAST_Sucursal && touched.GAST_Sucursal ? (
//                     <Typography color="error" variant="subtitle2">
//                       {errorForm.GAST_Sucursal}
//                     </Typography>
//                   ) : null}
//                 </Grid>
//                 <Grid item md={6} xs={12}>
//                   <TextField
//                     fullWidth
//                     required
//                     label="Metodo gasto"
//                     name="GAST_Mtdpago"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     type="number"
//                     value={values.GAST_Mtdpago}
//                     select
//                   >
//                     {!!datasMtdPago &&
//                       datasMtdPago.map(option => (
//                         <MenuItem
//                           key={`mtd_${option.value}`}
//                           value={option.value}
//                         >
//                           {option.label}
//                         </MenuItem>
//                       ))}
//                   </TextField>
//                   {errorForm.GAST_Mtdpago && touched.GAST_Mtdpago ? (
//                     <Typography color="error" variant="subtitle2">
//                       {errorForm.GAST_Mtdpago}
//                     </Typography>
//                   ) : null}
//                 </Grid>
//                 <Grid item md={12} xs={12}>
//                   <ImageUploader
//                     withIcon={true}
//                     label="Archivo de max: 5mb, acepta jpg, png, jpeg"
//                     buttonText="Seleccione imagenes"
//                     singleImage={true}
//                     onChange={onDrop}
//                     imgExtension={['.jpg', '.png', '.jpeg']}
//                     maxFileSize={5242880}
//                     withPreview={true}
//                   />
//                 </Grid>
//               </Grid>
//             </CardContent>
//             <Divider />
//             <Box display="flex" justifyContent="flex-end" p={2}>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 size="large"
//                 className={classes.button}
//                 startIcon={<SaveIcon />}
//               >
//                 Guardar
//               </Button>
//             </Box>
//           </Card>
//           <Snack
//             open={snackOpen}
//             handleClose={handleCloseSnack}
//             message={snackMessage}
//             severity={snackSeverity}
//           />
//           <LoadingSpiner estado={loading} />
//         </form>
//       )}
//     </Formik>
//   );
// };

// ServiceForm.propTypes = {
//   className: PropTypes.string
// };

// export default ServiceForm;
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import {
  GET_DATA_SUCURSAL,
  GET_MTD_PAGO_GASTO_NEW
} from '../../../graphql/Selects';
import { NEW_GASTO } from '../../../graphql/Gasto';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import { MapeoSucursales } from '../../../Commons';
import { schemaGastos } from '../../../shared/validators/Forms';
import LoadingSpiner from '../../../components/LoadingSpiner';
import Snack from 'src/utils/Common';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
  root: {},
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  }
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [img, setImg] = useState('');
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [suc, setSucursal] = useState();

  const goBackButton = () => {
    history.push('gastos');
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setImg(pictureDataURLs[0]);
  };

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const handleChangeSuc = e => {
    if (e.target.value !== '') {
      setSucursal(e.target.value);
      loadMtdPago({ variables: { data: { Sucursal: e.target.value } } });
    }
  };

  const [datasSucursal, setDatasSucursal] = useState([]);
  const [datasMtdPago, setDatasMtdPago] = useState([]);
  const [
    loadMtdPago,
    { data: dataMtdPago, loading: loading3, error: error3 }
  ] = useLazyQuery(GET_MTD_PAGO_GASTO_NEW, {
    fetchPolicy: 'no-cache'
  });
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(
    GET_DATA_SUCURSAL
  );
  const [sendGasto] = useMutation(NEW_GASTO, {
    onCompleted: data => {
      if (data) {
        setSnackMessage('Se ha creado con éxito el gasto!');
        setSnackSeverity('success');
        setSnackOpen(true);
      }
      setTimeout(() => {
        history.push({
          pathname: '/app/gastos',
          state: { isRefetch: true }
        });
      }, 1000);
    }
  });

  const sendData = async values => {
    setLoading(true);
    setErrorForm({});
    let data = {
      GAST_NumFact: values.GAST_NumFact,
      GAST_Fecha: values.GAST_Fecha,
      GAST_Descripcion: values.GAST_Descripcion,
      GAST_Importe: values.GAST_importe,
      GAST_Sucursal: suc,
      GAST_User: itemSession.USR_Id,
      GAST_Mtdpago: values.GAST_Mtdpago,
      GAST_Recibo: img
    };
    schemaGastos
      .validate(data)
      .then(async res => {
        await sendGasto({ variables: { data } });
      })
      .catch(err => {
        setLoading(false);
        let object = {};
        object[err.path] = err.errors;
        setErrorForm(object);
      });
  };

  useEffect(() => {
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal);
      setDatasSucursal(thing);
    }
    if (dataMtdPago && loading3 === false && error3 === undefined) {
      let thing = [];
      dataMtdPago.GetDatamtdpagoDeposito1.map(item => {
        return thing.push({ label: item.CTA_Nombre, value: item.CTA_Id });
      });
      setDatasMtdPago(thing);
    }
  }, [dataSucursal, loading4, error4, dataMtdPago, loading3, error3]);

  return (
    <Formik
      initialValues={{
        GAST_Fecha: new Date().toISOString().substring(0, 10),
        GAST_Descripcion: '',
        GAST_importe: '',
        GAST_Sucursal: '',
        GAST_recibo: '',
        GAST_NumFact: '',
        GAST_Mtdpago: ''
      }}
      onSubmit={async (values, actions) => {
        sendData(values);
      }}
    >
      {({ values, touched, handleChange, handleBlur, handleSubmit }) => (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
        >
          <Card>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CardHeader
                  title="Genera un nuevo gasto"
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Ir atrás">
                        <IconButton
                          edge="end"
                          color="inherit"
                          // onClick={() => {
                          //   history.push('.');
                          // }}
                          onClick={goBackButton}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                />
                {/* <Button
                  className={classes.buttonStyle}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={goBackButton}
                >
                  Atras
                </Button> */}
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Fecha de servicio"
                    type="date"
                    name="GAST_Fecha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.GAST_Fecha}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />                  
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Gasto en"
                    name="GAST_Descripcion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.GAST_Descripcion}
                  />
                   {errorForm.GAST_Descripcion && touched.GAST_Descripcion ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.GAST_Descripcion}
                    </Typography>
                  ) : null}                 
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Importe"
                    name="GAST_importe"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.GAST_importe}
                  />
                  {errorForm.GAST_importe && touched.GAST_importe ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.GAST_importe}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    select
                    label="Sucursal"
                    name="GAST_Sucursal"
                    onChange={handleChangeSuc}
                    value={suc}
                  >
                    {datasSucursal.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {errorForm.GAST_Sucursal && touched.GAST_Sucursal ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.GAST_Sucursal}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    select
                    label="Método de pago"
                    name="GAST_Mtdpago"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.GAST_Mtdpago}
                  >
                    {datasMtdPago.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {errorForm.GAST_Mtdpago && touched.GAST_Mtdpago ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.GAST_Mtdpago}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Número de factura"
                    name="GAST_NumFact"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.GAST_NumFact}
                  />
                  {errorForm.GAST_NumFact && touched.GAST_NumFact ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.GAST_NumFact}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={12}>
                  <ImageUploader
                    withIcon={true}
                    buttonText="Sube tu comprobante"
                    onChange={onDrop}
                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                    maxFileSize={5242880}
                    singleImage={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              {loading ? (
                <LoadingSpiner />
              ) : (
                <>
                  <Button
                    // className={classes.buttonStyle}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                </>
              )}
            </Box>
          </Card>
          <Snack
            open={snackOpen}
            message={snackMessage}
            severity={snackSeverity}
            handleClose={handleCloseSnack}
          />
        </form>
      )}
    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;
