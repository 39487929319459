import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,  
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,  
  colors
} from '@material-ui/core';


const Sales = ({ data }) => {
  // console.log('Debug', datos2)
  const theme = useTheme();  
  const [datos, setdatos] = useState([]);  
  useEffect(() => {
    // Captura los datos enviados desde el prop
    // console.log('Data recibida en Sales:', data);
    setdatos(data); // Actualiza el estado con los datos recibidos
  }, [data]);
  

  // const data = {
  //   datasets: [
  //     {
  //       backgroundColor: colors.indigo[500],
  //       // data: [18, 5, 19, 27, 29, 19, 20],
  //       data: datos.GetAllSellMonth.map(item => item.SUC_Nombre),
  //       label: 'This year'
  //     },
  //     {
  //       backgroundColor: colors.grey[200],
  //       // data: [11, 20, 12, 29, 30, 25, 13],
  //       data: datos.GetAllSellMonth.map(item => item.Total),
  //       label: 'Last year'
  //     }
  //   ],
  //   labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug']
  // };
  const chartData = {
    labels: datos.map(item => item.SUC_Nombre) || [], // Etiquetas para el eje X
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: datos.map(item => item.Total) || [], // Valores para las barras
        label: 'This year'
      }
    ]
  };

   // Log de las etiquetas para verificar los datos
  //  console.log('Labels:', chartData.labels);

  // const options = {
  //   animation: true,
  //   cornerRadius: 20,
  //   layout: { padding: 0 },
  //   legend: { display: false },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   scales: {
  //     xAxes: [
  //       {
  //         barThickness: 12,
  //         maxBarThickness: 10,
  //         barPercentage: 0.5,
  //         categoryPercentage: 0.5,
  //         ticks: {
  //           fontColor: theme.palette.text.secondary
  //         },
  //         gridLines: {
  //           display: false,
  //           drawBorder: false
  //         }
  //       }
  //     ],
  //     yAxes: [
  //       {
  //         ticks: {
  //           fontColor: theme.palette.text.secondary,
  //           beginAtZero: true,
  //           min: 0
  //         },
  //         gridLines: {
  //           borderDash: [2],
  //           borderDashOffset: [2],
  //           color: theme.palette.divider,
  //           drawBorder: false,
  //           zeroLineBorderDash: [2],
  //           zeroLineBorderDashOffset: [2],
  //           zeroLineColor: theme.palette.divider
  //         }
  //       }
  //     ]
  //   },
  //   tooltips: {
  //     backgroundColor: theme.palette.background.default,
  //     bodyFontColor: theme.palette.text.secondary,
  //     borderColor: theme.palette.divider,
  //     borderWidth: 1,
  //     enabled: true,
  //     footerFontColor: theme.palette.text.secondary,
  //     intersect: false,
  //     mode: 'index',
  //     titleFontColor: theme.palette.text.primary
  //   }
  // };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          color: theme.palette.text.secondary
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        backgroundColor: theme.palette.background.default,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        enabled: true,
        footerColor: theme.palette.text.secondary,
        intersect: false,
        mode: 'index',
        titleColor: theme.palette.text.primary
      }
    }
  };

  return (
    <Card
   
    >
      <CardHeader
        // action={(
        //   <Button
        //     endIcon={<ArrowDropDownIcon />}
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days
        //   </Button>
        // )}
        title="Ventas del Mes" style={{ backgroundColor: "#138cd4", color: '#FFFFFF' }}
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={chartData}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      {/* <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box> */}
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string  
};

export default Sales;
