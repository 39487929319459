import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
// import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Switch from '@material-ui/core/Switch';
import { useLocation } from 'react-router-dom';
import { EDIT_TAX } from '../../../../graphql/Tax';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));
const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);
  const initialValues = {
    TAX_Id: location.state.TAX_Id,
    TAX_Valor: location.state.TAX_Valor,
    TAX_Activo: location.state.TAX_Activo === 1 ? true : false
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [sendTax] = useMutation(EDIT_TAX, {
    onCompleted: data => {
      // console.log(data);
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/tax',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        TAX_Id: Yup.string().max(5).required('Campo requerido!'),
        TAX_Valor: Yup.string().max(20).required('Campo requerido!'),
      })}
      onSubmit={async (values, actions) => {
        await sendTax({
          variables: {
            data: {
              TAX_Id: values.TAX_Id,
              TAX_Valor: values.TAX_Valor,
              TAX_Activo: values.TAX_Activo !== false ? 1 : 0,
              TAX_ByModify: itemSession.USR_Id,
            },
          }
        })
        actions.resetForm({
          values: {
            TAX_Id: '',
            TAX_Valor: '',
            TAX_Activo: '',
            TAX_ByModify: '',
          },
          // you can also set the other form states here
        });
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Modifica una impuesto"
              title="Impuesto"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    disabled
                    label="Impuesto Id"
                    name="TAX_Id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.TAX_Id}
                  />
                  {errors.TAX_Id && touched.TAX_Id ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.TAX_Id}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Valor del impuesto"
                    name="TAX_Valor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.TAX_Valor}
                  />
                  {errors.TAX_Valor && touched.TAX_Valor ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.TAX_Valor}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid 
                  item
                  md={12}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="subtitle2">
                        Activa
                    </Typography>
                    <Switch size="small" name="TAX_Activo" checked={values.TAX_Activo} onChange={handleChange} />
                  </Box>
                </Grid>        
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Guardar Impuesto
                </Button>
            </Box>
          </Card>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha creado con exito!
              </Alert>
          </Snackbar>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;