import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles,
  Grid,
  Box,
  Button
} from '@material-ui/core';
import {NewFormatDateShort,RowsxList} from '../../Commons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Visibility,Edit } from '@material-ui/icons';

const columns = [
  { field: 'id', headerName: 'ID', width: 130 },
  { field: 'Fecha', headerName: 'Fecha Inicio', width: 130 },
  { field: 'FIC_Dni', headerName: 'DNI', width: 130 },
  { field: 'FIC_NombreCom', headerName: 'Nombre Completo', width: 130 },
  { field: 'FIC_Celular', headerName: 'Celular', width: 130 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }
}));

const DataTable = (props,{ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const location = useLocation();
  // const [error, setError] = useState(null); // State para manejar errores

  const handleClick = (tipo,data) => {
    if(tipo === 'edit'){
      history.push({
        pathname: 'editLegajo',
        state: data
      })
    } else {
      history.push({
        pathname: 'verLegajo',
        state: data
      })
    }
  }
  
  useEffect(() => {
    let thing = []
    if (props.servicesData && props.loading_Services === false && props.error_Services === undefined) {
      props.servicesData.GetLegajo.map(item => {
        item['id'] = item.FIC_Id;        
        item['Fecha'] = NewFormatDateShort(item.FIC_FIngreso);
        
        return thing.push(item)
      })
      setDataTable(thing);
    }
    // console.log('Debug: ', thing)
  }, [props.servicesData, props.error_Services, props.loading_Services, location])
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <Box display="flex" justifyContent="flex-start">
            <CardHeader title="Legajo" />

          </Box>

        </Grid>

        <Grid
          item
          md={6}
          xs={12}
        >

          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <RouterLink className={classes.buttonStyle} to='/app/NewLegajo'>
              <Button
                color="primary"
                variant="contained"
              >
                Nuevo
              </Button>
            </RouterLink>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table >
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                <TableCell className={classes.head} >
                   Ver
                  </TableCell>
                  <TableCell className={classes.head} >
                   Modificar
                  </TableCell>
                {/* <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order,i) => {
                return (
                  <TableRow style={ i % 2? { background : "#f4f6f8" }:{ background : "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Visibility onClick={() => handleClick('ver',order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit',order)} />
                    </TableCell>
                  </TableRow>
                );
              })}              
            </TableBody>            
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default DataTable;