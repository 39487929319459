import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';


// import { EDIT_GASTO,NEW_GASTO } from '../../../graphql/Gasto';
import { NEW_COUNTWEB } from 'src/graphql/CountWeb';
import {
  GET_DATA_SUCURSAL_FILTER_ACTIVE,  
} from '../../../graphql/Selects';

import LoadingSpiner from '../../../components/LoadingSpiner';
import Snack from 'src/utils/Common';

import * as Yup from 'yup';


const useStyles = makeStyles(() => ({
  root: {},
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  }
}));

const ServiceForm = ({ className, initialValues, isEdit, ...rest }) => {
  initialValues={  
    MSG_Fecha: new Date().toISOString().substring(0, 10)
  }            
    isEdit=false;
  const classes = useStyles();  
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState({});  
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);
  //Contenido del select de sucursales
  const [datasSucursal, setDatasSucursal] = useState([]);
  // const [suc, setSucursal] = useState();

  const validationSchema = Yup.object().shape({
    MSG_MessageWeb: Yup.number().required('Requiere la cantidad de mensajes.')
  })

  // const goBackButton = () => {
  //   history.push('MensajeWeb');
  // };

  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  
 
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(
    GET_DATA_SUCURSAL_FILTER_ACTIVE
  );
  const [sendCount] = useMutation( NEW_COUNTWEB, {
    onCompleted: data => {      
      if (data) {
        setOpen(true);
      }    
      setTimeout(() => {
        history.push({
          pathname: '/app/MensajeWeb',
          state: { isRefetch: true }
        });
      }, 1000);
    }
  });
  //Funcion para enviar datos al backend
  const sendData = async values => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm({});
    //Creo el objeto a validar
    let data = {
      
      MSG_CreatedDateTime: values.MSG_Fecha,     
      MSG_Sucursal: values.MSG_Sucursal,
      MSG_MessageWeb: values.MSG_MessageWeb,
      MSG_Modifiedby: itemSession.USR_Id
      
    };
    
    validationSchema.validate(data)
    .then(async res => {      
        await sendCount({ variables: { data } });
    }).catch(err => {
      setLoading(false);
      let object = {};
      object[err.path] = err.errors;
      setErrorForm(object);
    });   
  };

  useEffect(() => {
    setLoading(true)
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = []
      dataSucursal.GetDataSucursalFilter.map(item => {
        return thing.push({ label: item.SUC_Nombre, value: item.SUC_Id })
      })
      setDatasSucursal(thing)
      setLoading(false)
    }
  },[dataSucursal,
    loading4,
    error4]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        sendData(values);
      }}
    >
      {({ values, touched, handleChange, handleBlur, handleSubmit }) => (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
        >
          <Card>
            <Grid container spacing={3}>
              <Grid item className={classes.headerStyle} md={6} xs={12}>
                <CardHeader title={isEdit ? "Editar" : "Mensaje Web"} />
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Fecha de mensaje"
                    type="date"
                    name="MSG_Fecha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.MSG_Fecha}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  {errorForm.MSG_Fecha && touched.MSG_Fecha ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.MSG_Fecha}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    select
                    name="MSG_Sucursal"
                    label="Sucursal"
                    onBlur={handleBlur}
                    value={values.MSG_Sucursal}
                    onChange={handleChange}
                  >
                    {!!datasSucursal &&
                      datasSucursal.map(option => (
                        <MenuItem
                          key={`sucursal_${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  {errorForm.MSG_Sucursal && touched.MSG_Sucursal ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.MSG_Sucursal}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Mensajes recibidos"
                    name="MSG_MessageWeb"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number" // Tipo de input es number
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restringe la entrada solo a números
                    value={values.MSG_MessageWeb}
                  />
                  {errorForm.MSG_MessageWeb && touched.MSG_MessageWeb ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.MSG_MessageWeb}
                    </Typography>
                  ) : null}
                </Grid>



              </Grid>              
            </CardContent>

            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}                
              >
                {isEdit ? "Actualizar" : "Guardar"}
              </Button>
            </Box>
          </Card>

          <Snack open={open} handleClose={handleClose} message="¡Mensajes Guardados!" />          
          <LoadingSpiner estado={loading} />
        </form>
      )}
    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool
};

export default ServiceForm;
