import { gql } from 'apollo-boost';

export const GET_ALL_SERVICIOS = gql`
         query GetVentaDxSucursal($data: InputsDate) {
           GetVentaDxSucursal(data: $data) {
             servicios {
               SERV_SID_Id
               SERV_SID_Importe
               SERV_SID_ImporteNeto
               CTA_Tipo
               TIPO_Nombre
               MTD_Nombre
               SERV_Fecha
               SERV_Nafta
               SERV_ANafta
             }
             depositos {
               DP_Id
               DP_Fecha
               DP_Sucursal
               DP_Usuario
               DP_MtdDepo
               DP_Referencia
               DP_Monto
               DP_Recibo
               MTD_Nombre
               CTA_Nombre
             }
             gastos {
               GAST_Fecha
               GAST_Sucursal
               GAST_Descripcion
               GAST_Importe
               GAST_Recibo
               GAST_Id
               GAST_NumFact
               MTD_Nombre
             }
             sucData {
               SUC_Id
               SUC_Nombre
               SUC_Activo
               SUC_ByModify
               SUC_FechaM
               SUC_Nafta
               SUC_Comi
               SUC_Orden
               SUC_Vista
             }
           }
         }
       `;

export const GET_ALL_GASTOS = gql`
query GetAllGastos($data: InputsDate){
    GetAllGastos(data:$data){  
        GastosAll{
            GAST_Id
            GAST_Fecha
            GAST_Sucursal
            GAST_Descripcion
            MTD_Nombre
            GAST_Importe            
            GAST_NumFact
        }
    }
}
`;

export const GET_ALL_TurAddUser = gql`
query GetAllTurAddUser($data: InputsDateAllUser){
    GetAllTurAddUser(data:$data){ 
      AllTurAddUser{
            SUC_Nombre
            SERV_Sucursal
            SERV_Usuario
            Turnos
            Importe
            ImporteNeto
          }         
    }
}
`;

export const GET_ALL_TurAddUser1 = gql`
query GetAllTurAddUserD($data: InputsDateAllUser){
    GetAllTurAddUserD(data:$data){ 
      AllTurAddUserD{
            SUC_Nombre
            SERV_Sucursal
            SERV_Usuario
            Turnos
            Importe
            ImporteNeto
          }         
    }
}
`;

export const GET_ALL_SELLaBILL = gql `
query GetAllSellaBill($data: InputsDateSaB){
      GetAllSellaBill(data: $data){
          AllSellaBill{
              SUC_Nombre
              SERV_Estado
              SERV_Delete
              SERV_Fecha
              SUC_Activo
              Sucursal
              SUM_SERV_SID_Importe
              SUM_SERV_SID_ImporteNeto
              SUM_DP_Monto
              SUM_GAST_Importe
              Turnos
              Diferencia
            }
          }
        }
`;

export const GET_ALL_Haberes = gql `
query GetAllHaberes($data: InputsDateHAB){
  GetAllHaberes(data: $data){
          AllHaberes{
              DP_Id
              DP_Fecha
              DP_Sucursal
              DP_Usuario
              DP_MtdDepo
              DP_Referencia
              DP_Monto
              CTA_Nombre
            }
          }
        }
`;

export const GETALLCLIENTMONTH = gql `
query GetAllClientMonth($data: InputsDateACM){
  GetAllClientMonth(data: $data){
    AllClientMonth{
      SUC_Nombre
      STATUS_Nombre
      CLI_NombreCom
      CLI_Telefono
      CLI_Email
      CLI_Direccion
    }
  }
}    
`;

export const GET_ALL_LAST_ORDER = gql `
  query GetAllLastOrder($data: InputsAllLastOrder){
    GetAllLastOrder(data: $data){
      AllLastOrder{
        SERV_Id
        SUC_Nombre
        SERV_Fecha
        STATUS_Nombre
        SERV_Estado
      }
    }
  }
`;

export const GETTOPSERVICE = gql `
  query GetTopService($data: InputsDateACM){
    GetTopService(data: $data){
      TopService{
        TIPO_Nombre
        Cantidad
      }
    }
  }
`
