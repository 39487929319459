import { gql } from 'apollo-boost'


//Query
export const GET_ALL_COUNTWEB = gql `
  query GetCountWeb{
    GetCountWeb{
        MSG_Id
        MSG_CreatedDateTime
        MSG_Sucursal
        SUC_Nombre
        MSG_MessageWeb
        MSG_Modifiedby
        MSG_Active
        Total_Messages
    }
  }
`;

export const EDIT_COUNTWEB = gql`
  mutation EditCount($data: EditCountWebInput){
    EditCount(data: $data){
        MSG_Id
        MSG_CreatedDateTime
        MSG_Sucursal        
        MSG_MessageWeb
        MSG_Modifiedby
        MSG_Active        
    }
  }
`;

//mutation
export const NEW_COUNTWEB = gql`
  mutation createCountWeb($data: CountWebInput){
    createCountWeb(data: $data){    
        MSG_CreatedDateTime
        MSG_Sucursal
        MSG_MessageWeb
        MSG_Modifiedby    
    }
  }
`;