import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CardHeader,
  Divider,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import Toolbar from '../Toolbar';
import { NewFormatDateShort } from 'src/Commons';
import useSessionTimeout from 'src/hook/AuthSesion';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spaceButtom: {
    paddingBottom: '1%'  
  }
}));

const VerTax = () => {
  const classes = useStyles();
  const location = useLocation();
  // console.log(location.state)
  const data = location.state;
  useSessionTimeout();
  return (
    <Page
      className={classes.root}
      title="Impuesto"
    >
      <Container maxWidth="lg">
      <Toolbar className={classes.spaceButtom}  />
      <Card>
            <CardHeader
                title={`Impuesto ID: ${data.TAX_Id}`}
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography variant="body2" component="p">
                      Nombre:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography variant="body2" component="p">
                      {data.TAX_Id}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography variant="body2" component="p">
                      Valor:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography variant="body2" component="p">
                      {data.TAX_Valor}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography variant="body2" component="p">
                      Fecha de Modificación:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography variant="body2" component="p">
                      {NewFormatDateShort(data.TAX_FModificado)}
                    </Typography>
                  </Grid>                  
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography variant="body2" component="p">
                      Modificado por:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography variant="body2" component="p">
                      {(data.TAX_ByModify)}                      
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
      </Container>
    </Page>
  )
}

export default VerTax;
