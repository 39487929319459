import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import {
  GET_DATA_SUCURSAL,
  GET_MTD_PAGO_GASTO
} from '../../../graphql/Selects';
import { EDIT_GASTO } from '../../../graphql/Gasto';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { MapeoSucursales } from '../../../Commons';
import { schemaGastosEdit } from '../../../shared/validators/Forms';
import LoadingSpiner from '../../../components/LoadingSpiner';
import ImageUploader from 'react-images-upload';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(() => ({
  root: {},
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  buttonGoBack: {
    marginTop: '1%',
    marginRight: '1%',
  },
}));


const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const location = useLocation();
  const history = useHistory();

  const [errorForm, setErrorForm] = useState({});
  const [suc, setSucursal] = useState(location.state.GAST_Sucursal);
  const [img, setImg] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderingTime, setRenderingTime] = useState(0);

  const [initialValues, setInitialValues] = useState({
    GAST_Descripcion: '',
    GAST_Importe: '',
    GAST_Sucursal: '',
    GAST_NumFact: '',
    GAST_Mtdpago: ''
  })

  
  const onDrop = (pictureFiles, pictureDataURLs) => {
    setImg(pictureDataURLs[0]);
  }

  const handleChangeSuc = e => {
    if (e.target.value !== '') {
      setSucursal(e.target.value);
      loadMtdPago({ variables: { data: { Sucursal: e.target.value } } });
    }
  };

  

  const goBackButton = () => {
    history.push('gastos')
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const [datasSucursal, setDatasSucursal] = useState([])
  const [datasMtdPago, setDatasMtdPago] = useState([])
  const [
    loadMtdPago,
    { data: dataMtdPago, loading: loading3, error: error3 }
  ] = useLazyQuery(GET_MTD_PAGO_GASTO, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      console.log('Data MTD Pago:', data);
      const mtdPagoOptions = data.GetDatamtdpagoGasto.map(item => ({
        label: item.CTA_Nombre,
        value: item.SSM_CTA_Id
      }));
      setDatasMtdPago(mtdPagoOptions);
    }
  });
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  const [sendGasto] = useMutation(EDIT_GASTO, {
    onCompleted: data => {
      if (data) {
        setOpen(true)
        setTimeout(() => {
          history.push({
            pathname: '/app/gastos',
            state: { isRefetch: true },
          });
        }, 1000)

      }
      // <Redirect to='/app/service' />
    }
  })
  //Funcion para enviar datos al backend
  const sendData = async (values) => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm({})
    //Creo el objeto a validar
    let data = {
      GAST_Id: location.state.GAST_Id,
      GAST_NumFact: values.GAST_NumFact,
      GAST_Descripcion: values.GAST_Descripcion,
      GAST_Importe: values.GAST_Importe,
      GAST_Sucursal: suc,
      GAST_User: itemSession.USR_Id,
      GAST_Mtdpago: values.GAST_Mtdpago,
      GAST_Recibo: img,
      GAST_LastRecibo: location.state.GAST_Recibo
    }
    //Valido con promesa
    schemaGastosEdit.validate(data)
      //Si es valido se envia el formulario
      .then(async (res) => {
        await sendGasto({ variables: { data } })
      })
      //Si no se generan los errores. Err solo devuelve 1 error a la vez
      .catch((err) => {
        setLoading(false);
        let object = {}
        object[err.path] = err.errors
        setErrorForm(object)
      })

  }
  useEffect(() => {
    // console.log('Location State:', location.state);
    // console.log('Initial Values:', initialValues);
    // console.log('Data Mtd Pago:', dataMtdPago);

    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
    
    if (dataMtdPago && loading3 === false && error3 === undefined) {
      let thing = []
      const mtdPagoOptions = dataMtdPago.GetDatamtdpagoGasto.map(item => {
        return thing.push({ label: item.CTA_Nombre, value: item.SSM_CTA_Id });
      });
      setDatasMtdPago(mtdPagoOptions)
    }
    if (loading4 === false) {
      if (renderingTime === 0) {
        // setValuesForm()
      }
    }
    // Establecer valores iniciales del formulario
  if (location.state && !renderingTime) {
    setValuesForm();
  }
  }, [dataSucursal,
    loading4,
    error4,
    location,
    renderingTime,
    dataMtdPago,
    loading3,
    error3
  ])
  const setValuesForm = useCallback(() => {
    setInitialValues(
      {
        GAST_Descripcion: location.state.GAST_Descripcion || '',
        GAST_Importe: location.state.GAST_Importe || '',
        GAST_Sucursal: location.state.SUC_Nombre !== undefined ? location.state.GAST_Sucursal : '',
        GAST_NumFact: location.state.GAST_NumFact || '',
        GAST_Mtdpago: location.state.GAST_Mtdpago || ''
      }
    )
    loadMtdPago({ variables: { data: { Sucursal: location.state.GAST_Sucursal } } });
    setRenderingTime(1)
  })
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await sendData(values);
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                // className={classes.headerStyle}
                xs={12}
              >
                <CardHeader                  
                  title={`Modificando Gasto ID: ${location.state.GAST_Id}`}                  
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                        {/* <Button                                        
                          color="inherit"
                          size="large"             
                          onClick={ GoToNew}       
                          startIcon={<AddIcon />}
                        >
                          Nuevo
                        </Button> */}
                      {/* </RouterLink> */}
      
                      <Tooltip title="Ir atrás">
                        <IconButton
                          edge="end"
                          color="inherit"
                          // onClick={() => {
                          //   history.push('.');
                          // }}
                          onClick={goBackButton}
                        >                          
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }

                />
                {/* <Button
                  className={classes.buttonGoBack}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={goBackButton}
                >
                  Atras
                </Button> */}
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Gasto en"
                    name="GAST_Descripcion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.GAST_Descripcion}
                  />
                  {errorForm.GAST_Descripcion && touched.GAST_Descripcion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.GAST_Descripcion}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Importe"
                    name="GAST_Importe"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.GAST_Importe}
                  />
                  {errorForm.GAST_Importe && touched.GAST_Importe ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.GAST_Importe}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Num factura"
                    name="GAST_NumFact"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.GAST_NumFact}
                  />
                  {errorForm.GAST_NumFact && touched.GAST_NumFact ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.GAST_NumFact}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    select
                    name="GAST_Sucursal"
                    label="Sucursal"
                    onBlur={handleBlur}
                    value={suc}
                    onChange={handleChangeSuc}
                  >
                    {!!datasSucursal && (datasSucursal.map((option) => (
                      <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.GAST_Sucursal && touched.GAST_Sucursal ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.GAST_Sucursal}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Metodo gasto"
                    name="GAST_Mtdpago"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='number'
                    value={values.GAST_Mtdpago}
                    select
                  >
                    {!!datasMtdPago && (datasMtdPago.map((option) => (
                      <MenuItem key={`mtd_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.GAST_Mtdpago && touched.GAST_Mtdpago ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.GAST_Mtdpago}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <ImageUploader
                    withIcon={true}
                    defaultImages={img !== '' ? [img] : [location.state.GAST_Recibo]}
                    label="Archivo de max: 5mb, acepta jpg, png, jpeg"
                    buttonText='Seleccione imagenes'
                    singleImage={true}
                    onChange={onDrop}
                    imgExtension={['.jpg', '.png','.jpeg']}
                    maxFileSize={5242880}
                    withPreview={true}
                    />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Modificar
                </Button>
            </Box>

          </Card>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha editado con exito el gasto!
              </Alert>
          </Snackbar>
          <LoadingSpiner estado={loading} />
        </form>
      )}
    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;