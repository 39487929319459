import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const TrafficByDevice = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [datos, setdatos] = useState([]);  
  
  const handleSucursalChange = (event) => {
  // Lógica para manejar el cambio
  console.log(event.target.value);
  // SetSucursalSearch(e.target.value)
};

  useEffect(() => {
    // Captura los datos enviados desde el prop
    // console.log('Data recibida en Sales:', data);
    setdatos(data); // Actualiza el estado con los datos recibidos
  }, [data]);

  
  const generateColors = (numColors) => {
    const colorPalette = [
      colors.indigo?.[500],
      colors.red?.[600],
      colors.orange?.[600],
      colors.green?.[500],
      colors.purple?.[500],
      colors.yellow?.[600],
      colors.blue?.[500],
      colors.pink?.[500],
      colors.teal?.[500],
      colors.brown?.[500],
      colors.lime?.[600],
      colors.cyan?.[500],
      colors.deepPurple?.[500],
      colors.lightGreen?.[500],
      colors.deepOrange?.[500],
      colors.amber?.[500],
      colors.grey?.[600],
      colors.lightBlue?.[500],
      colors.blueGrey?.[500],
      colors.darkGreen?.[500], // Algunos de estos colores podrían no estar disponibles
      colors.darkOrange?.[500], // Dependiendo de tu configuración de Material-UI
    ].filter(Boolean); // Filtra cualquier valor undefined
    
    // Genera un array de colores, repitiendo si es necesario
    return Array.from({ length: numColors }, (_, i) => colorPalette[i % colorPalette.length]);
  };

  const chartdata = {
    labels: datos.map(item => item.TIPO_Nombre) || [],
    datasets: [
      {
        // backgroundColor: colors.indigo[500],
        backgroundColor: generateColors(datos.length),
        data: datos.map(item => item.Cantidad) || [],
        borderWidth: 1,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ]
  }

  const options = {
    animation: false,
    cutoutPercentage: 60,
    layout: { padding: 0},
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 3,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'point',
      xPadding: 10,
      yPadding: 10,
      titleFontColor: theme.palette.text.primary,
      callbacks: {
        // Custom tooltip callback to split the text into multiple lines
        label: function(tooltipItem, data) {
          const label = data.labels[tooltipItem.index] || '';
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
  
          // Split the label into multiple lines if needed
          const labelLines = label.split(' '); // Puedes dividir por espacio u otro delimitador
  
          // Retorna un array donde cada elemento será una línea en el tooltip
          return [`${labelLines.join(' ')}`, `Cantidad: ${value}`];
        }
      }
    }
  };


  return (
    <Card      
    >
      <CardHeader title="Servicios Prestados" style={{ backgroundColor: "#138cd4", color: '#FFFFFF' }} />
      <Divider />
      <CardContent>
        <Box
          height={393}
          position="relative"
        >
          <Box>
          
          </Box>
          <Doughnut
            data={chartdata}
            options={options}
          />
        </Box>
        
      </CardContent>
    </Card>
  );
};

TrafficByDevice.propTypes = {
  className: PropTypes.string
};

export default TrafficByDevice;
