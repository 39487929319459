import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useSessionTimeout = () => {
  const history = useHistory();

  useEffect(() => {
    const sessionStartTime = localStorage.getItem('sessionStartTime');
    console.log("Verificando sesión..."); // Validar si el hook se está ejecutando
    if (sessionStartTime) {
      const sessionDuration = 60 * 60 * 1000; // 5 minutos en milisegundos
      const currentTime = Date.now();

      if (currentTime - sessionStartTime > sessionDuration) {
        // Si la sesión ha expirado, limpiamos el localStorage y redirigimos al login
        console.log("Sesión expirada. Redirigiendo al login...");
        localStorage.clear();
        history.push('/')
      }
    } else {
      // Si no hay un tiempo de inicio de sesión, redirigir al login
      console.log("No hay tiempo de inicio. Redirigiendo al login...");
      history.push('/')
    }
  }, [history]);
};

export default useSessionTimeout;
