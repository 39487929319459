import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { GET_ALL_LAST_ORDER } from "../../graphql/Report";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { NewFormatDateLong1 } from 'src/Commons';
import { GET_SERV_STATUS } from 'src/graphql/Selects';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExportIcon from '@material-ui/icons/SaveAlt';
import { generatePDF } from 'src/utils/GeneralPDF';
import Snack from 'src/utils/Common';
import { useHistory } from 'react-router-dom';

const columns = [
  { field: 'SERV_Id', headerName: '#', width: 130, },
  { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'SERV_Fecha', headerName: 'Servicio', width: 130 },
  { field: 'STATUS_Nombre', headerName: 'Turnos ', width: 100 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]);
  const [dataServStatus, setDataServStatus] = useState([]);
  const [servEstado, setServEstado] = useState(2); // Estado para SERV_Estado
  const [loadingExport, setLoadingExport] = useState(false); // Estado para manejar la carga de exportación
  const history = useHistory();

  // Snack
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(''); // Añadido para manejar la severidad

  const { data: dataStatus, loading: loading1, error: error1 } = useQuery(GET_SERV_STATUS);

  const [findSales] = useLazyQuery(GET_ALL_LAST_ORDER, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error('Error al ejecutar la consulta:', error);
    },
    onCompleted: (data) => {
      setDataTable(data.GetAllLastOrder);
    },
  });

  useEffect(() => {
    if (dataStatus && !loading1 && !error1) {
      const statusOptions = dataStatus.GetDataServStatus.map(item => ({
        label: item.STATUS_Nombre,
        value: item.STATUS_Id
      }));
      setDataServStatus(statusOptions);
    }

    // Cuando el componente se monta, cargar los datos
    findSales({
      variables: {
        data: {
          FechaIni: getFirstDayOfMonth(),
          FechaFin: getLastDayOfMonth(),
          SERV_Estado: servEstado
        },
      }
    });
  }, [findSales, dataStatus, loading1, error1, servEstado]);

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const handleExport = async (values) => {
    if (loadingExport) return; // Prevenir múltiples clics

    setLoadingExport(true); // Establecer estado de carga
    if (dataTable.AllLastOrder && dataTable.AllLastOrder.length > 0) {
      if (servEstado === values.SERV_Estado) { // Verificar si el estado coincide
        const reportTitle = `Servicios ${dataTable.AllLastOrder[0].STATUS_Nombre || 'Desconocido'}`;
        await generatePDF(reportTitle, columns, dataTable.AllLastOrder);

        // Mostrar mensaje de éxito
        setSnackMessage('Reporte exportado con éxito.');
        setSnackSeverity('success'); // Establecer severidad a éxito
      } else {
        // Mostrar mensaje de advertencia
        setSnackSeverity('warning'); // Establecer severidad a advertencia
        setSnackMessage('El estado del servicio ha cambiado. Actualiza la búsqueda antes de exportar.');
      }
    } else {
      // Mostrar mensaje de error
      setSnackSeverity('error'); // Establecer severidad a error
      setSnackMessage('No hay datos disponibles para exportar.');
    }

    setSnackOpen(true);
    setLoadingExport(false); // Restablecer estado de carga
  };

  // Función para obtener el último día del mes actual
  const getLastDayOfMonth = () => {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return lastDay.toISOString().split('T')[0];
  };

  // Función para obtener el primer día del mes actual
  const getFirstDayOfMonth = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    return firstDay.toISOString().split('T')[0];
  };

  return (
    <Page className={classes.root} title="Informe de rendicion">
      <Formik
        initialValues={{
          FechaIni: getFirstDayOfMonth(),
          FechaFin: getLastDayOfMonth(),
          SERV_Estado: 2
        }}
        onSubmit={async (values) => {
          setServEstado(values.SERV_Estado); // Actualizar el estado local
          await findSales({
            variables: {
              data: {
                FechaIni: values.FechaIni,
                FechaFin: values.FechaFin,
                SERV_Estado: values.SERV_Estado
              },
            }
          });
        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
        }) => (
          <Container maxWidth="lg">
            <CardContent>
              <Card>
                <CardHeader
                  title="Informe de turnos"
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          history.push('.');
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"
                          name="FechaIni"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaIni}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaIni && touched.FechaIni && (
                          <Typography color="error" variant="subtitle2">
                            {errors.FechaIni}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"
                          name="FechaFin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFin}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaFin && touched.FechaFin && (
                          <Typography color="error" variant="subtitle2">
                            {errors.FechaFin}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          select
                          name="SERV_Estado"
                          label="Estado servicio:"
                          onBlur={handleBlur}
                          value={values.SERV_Estado}
                          onChange={(event) => {
                            handleChange(event);
                            setServEstado(event.target.value); // Actualizar el estado local
                          }}
                        >
                          {dataServStatus.map((option) => (
                            <MenuItem key={`status_${option.value}`} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {errors.SERV_Estado && touched.SERV_Estado && (
                          <Typography color="error" variant="subtitle2">
                            {errors.SERV_Estado}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
                        </Button>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          endIcon={<ExportIcon />}                          
                          onClick={() => handleExport(values)} // Pasar `values` a `handleExport`
                          disabled={loadingExport} // Desactivar botón durante la carga
                        >
                          
                          Exportar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.field}
                          className={classes.head}
                        >
                          {column.headerName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataTable.AllLastOrder && dataTable.AllLastOrder.length > 0 ?
                      (
                        dataTable.AllLastOrder.map((row, index) => (
                          <TableRow key={index} className={classes.tableRow}>
                            <TableCell align="center">{row.SERV_Id}</TableCell>
                            <TableCell align="center">{row.SUC_Nombre}</TableCell>
                            <TableCell align="center">{NewFormatDateLong1(row.SERV_Fecha)}</TableCell>
                            <TableCell align="center">{row.STATUS_Nombre}</TableCell>
                          </TableRow>

                        )
                        )) : (
                        <TableRow>
                          <TableCell colSpan={columns.length} align="center">
                            No hay datos disponibles
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                  {/* <TableBody>
                    {dataTable.AllLastOrder && dataTable.AllLastOrder.map((row) => (
                      <TableRow key={row.SERV_Id} className={classes.tableRow}>
                        {columns.map(column => (
                          <TableCell key={column.field}>
                            {row[column.field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody> */}
                </Table>
              </TableContainer>
            </CardContent>
          </Container>
        )}
      </Formik>
      {/* SnackBar */}
      <Snack
        open={snackOpen}
        handleClose={handleCloseSnack}
        message={snackMessage}
        severity={snackSeverity}
      />
    </Page>
  );
};

export default DataTable;
