import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

export const Footer = () => {
  return (
    <Grid
      style={{
        backgroundColor: '#ffffff',
        borderTop: '1px solid #dbdcdf',
        height: '25px',
        borderRadius: '2px'
      }}
      container
    >
      <Grid
        container
        item
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Box>
          {/* <Typography style={{ fontWeight: 'bold', fontSize: '15px' }} variant="p">
            Desarrollado por{' '}
            <a href="https://example.com" target="_blank" rel="noopener noreferrer">Unelca</a>
            {' '}
            ©2021
          </Typography> */}
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://unelca.com/">
            Unelca
            </Link>{' '}
            2021 - {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
