import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Switch from '@material-ui/core/Switch';
import { useLocation } from 'react-router-dom';
import { EDIT_SUCURSAL } from '../../../../graphql/Sucursales';
import { GET_PAISES } from 'src/graphql/Selects';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);
  
  // console.log('Debug', location)  
  
  const initialValues = {
    SUC_Nombre: location.state.SUC_Nombre,
    SUC_Activo: location.state.SUC_Activo !== 0 ? true : false,
    SUC_Id: location.state.SUC_Id,
    SUC_Nafta: location.state.SUC_Nafta,
    SUC_Comi: location.state.SUC_Comi,
    PAI_Id: location.state.SUC_CodePhone
  }

  const [loading, loadingState] = useState(true);
//   const [errorForm, setErrorForm] = useState({});
const [dataPaises, setDataPaises] = useState([])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
    
  const { data: datagetPaises, loading: loading1, error: error1 } = useQuery(GET_PAISES);
  useEffect(() => {    

    if (datagetPaises && loading1 === false && error1 === undefined) {
      let thing = []
      datagetPaises.GetDataPaises.map(item => {
        return thing.push({ label: item.PAI_Name, value: item.PAI_Id })
      })
      setDataPaises(thing)
    }
    
    if (loading1 === false ) {
      loadingState(false)
    }
  }, [
    datagetPaises,
    loading,
    loading1,
    error1
  ])

  const [sendMtdPago] = useMutation(EDIT_SUCURSAL, {
    onCompleted: data => {
      // console.log('Debug:' , data);
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/Sucursal',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })

  const goBackButton = () => {
    // history.push('services')
    history.push({
      pathname: 'Sucursal',
      state: { isRefetch: true }
    })  
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({      
        SUC_Nombre: Yup.string().max(25).required('Campo requerido!'),        
      })}
      onSubmit={async (values, actions) => {
        
        await sendMtdPago({
          variables: {
            data: {
              SUC_Id: values.SUC_Id,
              SUC_Nombre: values.SUC_Nombre,
              SUC_Nafta: values.SUC_Nafta,
              SUC_Activo: values.SUC_Activo !== false ? 1 : 0,
              SUC_ByModify: itemSession.USR_Id,
              SUC_Comi: values.SUC_Comi,
              SUC_CodePhone: values.PAI_Id,   
            },            
          }
        })
        actions.resetForm({
          values: {
            SUC_Nombre: '',
            SUC_Id: '',
            SUC_Activo: '',
            SUC_ByModify: '',
            SUC_Nafta: '',
            SUC_Comi: '',
            SUC_CodePhone: '',            
          },
          // you can also set the other form states here
        });
      }}
      >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (        
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              title={`Modifica una sucursal:  ${location.state.SUC_Nombre}`}
              style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  {/* <Button                                        
                    color="inherit"
                    size="large"             
                    onClick={ GoToNew}       
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button> */}
                {/* </RouterLink> */}

                <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </div>
            }
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={4}
              >   
                <Grid item md={6} xs={12} >
                <TextField
                    fullWidth
                    select
                    name="PAI_Id"
                    label="Origen Sucursal:"
                    onBlur={handleBlur}
                    value={values.PAI_Id}
                    onChange={handleChange}
                  >
                    {!!dataPaises && (dataPaises.map((option) => (
                      <MenuItem key={`PAI_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                    
                  </TextField>               
                </Grid>            
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Sucursal:"
                    name="SUC_Nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.SUC_Nombre}
                    disabled={true}                    
                  />
                  {errors.SUC_Nombre && touched.SUC_Nombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.SUC_Nombre}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nafta por servicio:"
                    name="SUC_Nafta"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.SUC_Nafta}
                    inputProps={{ type: 'number', pattern: '[0-9]*' }}                     
                  />
                  {errors.SUC_Nafta && touched.SUC_Nafta ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.SUC_Nafta}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Comision Operario:"
                    name="SUC_Comi"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.SUC_Comi}
                    inputProps={{ type: 'number', pattern: '[0-9]*' }}  // Permite solo números                    
                  />
                  {errors.SUC_Comi && touched.SUC_Comi ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.SUC_Comi}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid 
                  item
                  md={12}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="subtitle2">
                        Activa
                    </Typography>
                    <Switch size="small" name="SUC_Activo" checked={values.SUC_Activo} onChange={handleChange} />
                  </Box>
                </Grid>                           
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Guardar
                </Button>
            </Box>
          </Card>
          <Snackbar open={open} autoHideDuration={7000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se modifico con exito la surcursal!
              </Alert>
          </Snackbar>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;