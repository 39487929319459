import moment from 'moment-timezone';
import {makeStyles} from '@material-ui/core';


const userPer = [
  {
    tipo: 3,
    vistas:['Turnos', 'NewServices', 'EditServices', 'VerServicios',
            'Gastos', 'NewGastos', 'VerGastos', 'EditGastos',
            'Depositos', 'NewDeposito', 'VerDepositos', 'EditDeposito',
            'Reportes', 'ListReport', 'DataTable', 
            'Inventario', 'NewInventario', 'EditInventario',
            'Configuración'],
    paths: ['services', 'newService', 'EditService', 'verServicio', 
            'gastos', 'newGasto', 'verGasto', 'EditGasto', 
            'depositos', 'newDeposito', 'verDeposito', 'editDeposito', 
            'reports', 'ReportSaleDay', 
            'inventario', 'NewInventario', 'EditInventario']
  },
  // {
  //   tipo: 4,
  //   vistas: ['Servicios', 'NewServices', 'EditServices', 'VerServicios'],
  //   paths: ['services', 'newService', 'EditService', 'verServicio']
  // },
  {
    tipo: 4,
    vistas: ['Turnos', 'NewServices', 'EditServices', 'VerServicios', 'Gastos', 'NewGastos', 'VerGastos', 'EditGastos', 'Depositos', 'NewDeposito', 'VerDepositos', 'EditDeposito', 'Reportes','ListReport','DataTable'],
    paths: ['services', 'newService', 'EditService', 'verServicio', 'gastos', 'newGasto', 'verGasto', 'EditGasto', 'depositos', 'newDeposito', 'verDeposito', 'editDeposito', 'reports', 'ReportSaleDay']
  },
  {
    tipo: 5,
    vistas: ['Reportes', 'ListReport', 'DataTable'],
    paths: ['reports', 'ReportSaleDay']
  },
  {
    tipo: 6,
    vistas: ['Turnos', 'NewServices', 'EditServices', 'VerServicios', 'Gastos', 'NewGastos', 'VerGastos', 'EditGastos', 'Depositos', 'NewDeposito', 'VerDepositos', 'EditDeposito', 'Reportes', 'ListReport', 'DataTable'],
    paths: ['services', 'newService', 'EditService', 'verServicio', 'gastos', 'newGasto', 'verGasto', 'EditGasto', 'depositos', 'newDeposito', 'verDeposito', 'editDeposito', 'reports', 'ReportSaleDay']
  },
  {
    tipo:7,
    vistas: ['Turnos','Reportes', 'ListReport', 'DataTable'],
    paths: ['services', 'reports', 'ReportSaleDay']
  }

]

// const allRoutes = ['Inicio', 'Servicios', 'Gastos', 'Depositos', 'Usuarios', 'Inventario', 'Reportes','Metodo pago','Sucursal','Agregar Servicios','Parametros','Tax','Barrios']
// const allRoutes = ['Inicio', 'Servicios', 'Gastos', 'Depositos', 'Usuarios', 'Inventario', 'Reportes']
const allRoutes = ['Inicio', 'Turnos', 'Gastos', 'Depositos','MensajeWeb', 'Usuarios', 'Inventario', 'Reportes']

export const switchAuth = (nombreChild, auth) => {
  switch (auth) {
    case 1:
      return true;
    case 2:
      return true;
    case 3:
      if (userPer[0].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[0].paths[0]
      }
    case 4:
      if (userPer[1].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[1].paths[0]
      }
    case 5:
      if (userPer[2].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[2].paths[0]
      }
    case 6:
      if (userPer[3].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[3].paths[0]
      }
    case 7:
      if (userPer[4].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[4].paths[0]
      }
    default:
      return false
  }
}

export const arrayFrom = (auth) => {
  switch (auth) {
    case 3:
      return userPer[0].vistas
    case 4:
      return userPer[1].vistas
    case 5:
      return userPer[2].vistas
    case 6:
      return userPer[3].vistas
    case 7:
      return userPer[4].vistas
    default:
      return allRoutes;
  }
}

export const getEstadoId = (nombre) => {
  let name = '0'
  switch (nombre) {
    case 'Confirmado':
      name = '2'
      break;
    case 'Pendiente':
      name = '1'
      break;
    case 'Reprogramado':
      name = '4'
      break;
    case 'Cancelado':
      name = '3'
      break;
    default:
      name = '1'
      break;
  }
  return name
}



export const TurnoHours = [
  { value: '07:00:00', label: '07:00:00' },
  { value: '07:30:00', label: '07:30:00' },
  { value: '08:00:00', label: '08:00:00' },
  { value: '08:30:00', label: '08:30:00' },
  { value: '09:00:00', label: '09:00:00' },
  { value: '09:30:00', label: '09:30:00' },
  { value: '10:00:00', label: '10:00:00' },
  { value: '10:30:00', label: '10:30:00' },
  { value: '11:00:00', label: '11:00:00' },
  { value: '11:30:00', label: '11:30:00' },
  { value: '12:00:00', label: '12:00:00' },
  { value: '12:30:00', label: '12:30:00' },
  { value: '13:00:00', label: '13:00:00' },
  { value: '13:30:00', label: '13:30:00' },
  { value: '14:00:00', label: '14:00:00' },
  { value: '14:30:00', label: '14:30:00' },
  { value: '15:00:00', label: '15:00:00' },
  { value: '15:30:00', label: '15:30:00' },
  { value: '16:00:00', label: '16:00:00' },
  { value: '16:30:00', label: '16:30:00' },
  { value: '17:00:00', label: '17:00:00' },
  { value: '17:30:00', label: '17:30:00' },
  { value: '18:00:00', label: '18:00:00' },
  { value: '18:30:00', label: '18:30:00' },
  { value: '19:00:00', label: '19:00:00' },
  { value: '19:30:00', label: '19:30:00' },
  { value: '20:00:00', label: '20:00:00' },  
]

export const GetEquipoInv = [
  { value: 'Aspiradora de liquido', label: 'Aspiradora de liquido' },
  { value: 'Aspiradora de polvo', label: 'Aspiradora de polvo' },
  { value: 'Aspiradora de polvo y liquido', label: 'Aspiradora de polvo y liquido' },
  { value: 'Pulverizador', label: 'Pulverizador' },
  { value: 'Taladro', label: 'Taladro' },
  { value: 'Vaporizadora', label: 'Vaporizadora' },
]

export function MapeoSucursales(dataSucursal, user = JSON.parse(localStorage.getItem('sucursalesUser'))) {
  const sesTime = JSON.parse(localStorage.getItem('sesTime')) || {}; // Maneja el caso en que sea null
  let { UGP_Nombre } = sesTime; // Desestructura UGP_Nombre de sesTime
  let thing = []; // Asegúrate de inicializar 'thing'

  if (UGP_Nombre) {
    UGP_Nombre = UGP_Nombre.trim();
    if (UGP_Nombre === "Gerente" || UGP_Nombre === "Contador" || UGP_Nombre === "Develop" || UGP_Nombre === "Administrador") {
      dataSucursal.GetDataSucursal.map(item => {
        return thing.push({ label: item.SUC_Nombre, value: item.SUC_Id })
    })
  } else {
    user.map(item => {
      let finded = dataSucursal.GetDataSucursal.find((itemF) => itemF.SUC_Id === item.SSU_SUC_Id);
      // finded['SSU_Id'] = item.SSU_Id

      if (finded) {
        return thing.push({ label: finded.SUC_Nombre, value: finded.SUC_Id })
      } else
      {
        console.error(`No se encontró coincidencia para SSU_SUC_Id: ${item.SSU_SUC_Id}`);
        return null; // O maneja esto según tu lógica
      }
      //con el .find se obtienen solo los que cumplan la validacion
    })
  }
  return thing
}}

export function NewFormatDateShort(dataDate) {
  let fecha = moment.utc(dataDate).format("YYYY-MM-DD")
  return fecha
}

export function NewFormatDateLong(dataDate) {
  let fecha = moment.utc(dataDate).format("YYYY-MM-DD HH:mm:ss")
  return fecha
}

export function NewFormatDateLong1(dataDate) {
  // Convertir a la zona horaria de Argentina (GMT-3)
  return moment(dataDate).tz('America/Argentina/Buenos_Aires').format('DD/MM/YYYY HH:mm:ss');
}

export function NewFormatDateHour(dataDate) {
  let fecha = moment.utc(dataDate).format("HH:mm:ss")
  return fecha
}

// Registro para mostar
export const RowsxList = 20;

//Tiempo que dura cada turno
export function TimeForService(){
  //7199 para 2 horas
  //3599 para 1 hora
  return 3599
}


// Funcion cumple
export function calcularEdad(fecha) {
  var hoy = new Date();
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  return edad;
}



//*************Reportes***********//
export const DecimalesInReport = 0
export function getNafta(allDatos) {
  // console.log(allDatos)
  let naftaTotal = 0
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      naftaTotal += Number(allDatos[i].SERV_Nafta)
      // console.log("aaa" + naftaTotal)
    }
    return naftaTotal.toFixed(DecimalesInReport)
  } else {
    return (0).toFixed(DecimalesInReport)
  }

}

export function getANafta(allDatos) {
  // console.log(allDatos)
  let anaftaTotal = 0
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      anaftaTotal += Number(allDatos[i].SERV_ANafta)
    }
    return anaftaTotal.toFixed(DecimalesInReport)
  }
}

export function getComision(allDatos) {
  let comisionNeto = 0
  let comisionTotal = 0
  let comi = 0  
  if (allDatos && allDatos.length > 0){
    for (let i = 0; i < allDatos.length; i++){
      comisionTotal += Number(allDatos[i].SERV_SID_Importe)
      comisionNeto += Number(allDatos[i].SERV_SID_ImporteNeto)
      comi = Number(comisionTotal) - Number(comisionNeto)       
    }    
  }   
  if (comi === 0 ) {
    return (0).toFixed(DecimalesInReport)
  }else {
    return comi.toFixed(DecimalesInReport)
  }
  
}


export function getSumTotalServ(allDatos, tipo) {
  let sumTotal = 0
  let sumTotalElectronico = 0
  // console.log(allDatos)
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      if (allDatos[i].CTA_Tipo === 1) {
        sumTotal += Number(allDatos[i].SERV_SID_Importe)
      } else {
        sumTotalElectronico += Number(allDatos[i].SERV_SID_Importe)
      }

    }
  }
  if (tipo === 1) {
    return sumTotal.toFixed(DecimalesInReport)
  } else {
    return sumTotalElectronico.toFixed(DecimalesInReport)
  }  
}

export function getSumTotalServAll(allDatos) {
  let sumTotal = 0
  // let sumTotalElectronico = 0
  // console.log(allDatos)
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      if (allDatos[i].CTA_Tipo === 1) {
        sumTotal += Number(allDatos[i].SERV_SID_Importe)
      } 
    }
  }  
   return sumTotal.toFixed(DecimalesInReport)
  
}

export function getSumTotalDash(allDatos, tipo) {
  let sumTotal = 0
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      if (tipo === 'Caja') {
        sumTotal += Number(allDatos[i].SERV_SID_ImporteNeto);
      } else {
        sumTotal += Number(allDatos[i].SERV_SID_Importe);
      }

    }
  }
  return sumTotal.toFixed(DecimalesInReport)
}

export function getValorReal(allDatos, Comi){
  if( allDatos && allDatos.length > 0) {
    allDatos.map((item) => {
      // console.log(allDatos)
      let comiEmp = item.Ventasdelmes * Comi
      item.Ventasdelmes -= item.Nafta;
      item.Ventasdelmes -= comiEmp
      item.Ventasdelmes -= item.Resta
      item.Ventasdelmes -= item.Gasto
      // console.log(Comi)
      // console.log(item.Ventasdelmes)
      // console.log(item.Nafta)
      // console.log(item.Resta)
      // console.log(item.Gasto)
      // console.log(comiEmp)
      return item;
    })
    return allDatos;
  }
};

export function getSumTotalDepo(allDatos) {
  let DepoTotal = 0
  // let DepoTotalElectronico = 0
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      DepoTotal += Number(allDatos[i].DP_Monto)
      // console.log(DepoTotal)
    }
  }
  // console.log(DepoTotal.toFixed(2))
  return DepoTotal.toFixed(DecimalesInReport)
}

export function getGastoAyudante(allDatos, tipo) {
  let ayudantesTotal = 0
  let gasto = 0
  if (allDatos && allDatos.length > 0) {
    let stringThing = ''
    for (let i = 0; i < allDatos.length; i++) {
      stringThing = allDatos[i].GAST_Descripcion
      if (stringThing.toUpperCase() === "PAGAR EMPLEADOS" || stringThing.toUpperCase() === "PAGAR AYUDANTE") {
        ayudantesTotal += Number(allDatos[i].GAST_Importe)
      } else {
        gasto += Number(allDatos[i].GAST_Importe)
      }
    }
  }
  if (tipo === 'Ayudante') {
    // console.log("ayudante " + ayudantesTotal.toFixed(2))
    return ayudantesTotal.toFixed(DecimalesInReport)
  } else {
    // console.log("gasto " + gasto.toFixed(2))
    return gasto.toFixed(DecimalesInReport)
  }
}

export function getTotalARendir(ventas, comisiones, Deposito,Nafta) {
  let totalARendir = 0
  // let totalARendirRound = 0
  // console.log(ventas)
  // console.log(comisiones)
  // console.log(Deposito)
  if (ventas !== 0 && comisiones !== 0 && Deposito !== 0 && Nafta) {
    totalARendir = Number(ventas) - Number(comisiones) - Number(Deposito) - Number(Nafta)
  }
  return totalARendir.toFixed(DecimalesInReport)
}

export function getDeducibles(Gastos, Ayudante, Comision) {
  let Deducibles = 0
  const comisionValue = isNaN(Number(Comision)) ? 0 : Number(Comision);
      // console.log(Comision)
  if (Gastos !== 0 && Ayudante !== 0 && comisionValue !== 0) {
    Deducibles =  Number(Gastos) + Number(Ayudante) + Number(comisionValue)
  }
  return Deducibles.toFixed(DecimalesInReport)
}


/********Fin Reportes*********/

/********Fin Socio*********/

export function getSumTotalServSocio(allDatos) {
  let sumTotal = 0  
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {      
        sumTotal += Number(allDatos[i].SERV_SID_Importe)      
    }
  }  
    return sumTotal.toFixed(DecimalesInReport)
}

export function getTotalARendirSocio(ventas, comisiones,  Gastos, Nafta) {
  let totalARendir = 0  
  // console.log(ventas)
  // console.log(comisiones)
  // console.log(Deposito)
  // console.log(Gastos)
  // console.log(Nafta)
  if (ventas !== 0 && comisiones !== 0 && Gastos !== 0 && Nafta !== 0) {
    totalARendir = (Number(ventas) - Number(comisiones) - Number(Gastos) - Number(Nafta))*0.15
  }  
  return totalARendir.toFixed(DecimalesInReport)  
}

export function getTotalARendirSocio5(ventas, comisiones, Deposito, Gastos) {
  let totalARendir = 0  
  // console.log(ventas)
  // console.log(comisiones)
  // console.log(Deposito)
  if (ventas !== 0 && comisiones !== 0 && Deposito !== 0 && Gastos !== 0) {
    totalARendir = (Number(ventas) - Number(comisiones) - Number(Deposito) - Number(Gastos))*0.5
  }
    
  return totalARendir.toFixed(DecimalesInReport)  
}
/********Fin Reportes Socio *********/

/******** manejo de colores en el calendario */

export const CalendarColor = makeStyles(() => ({
      root: {},
      colorTableA: {
          color: "Black",
          background: "#d4eaff",
          textAlign: "center",      
      },
      colorTableC: {
          color: "white",
          background: "red",
          textAlign: "center",      
      },
      colorTableP: {
          color: "Black",
          background: "#ffffc0",
          textAlign: "center",      
      },
      colorTableR: {
          color: "white",
          background: "#FE9747",
          textAlign: "center",      
      }
    }))

/******** fin  manejo de colores en el calendario */
