import { jsPDF } from "jspdf";
import 'jspdf-autotable';

export const generatePDF = async (title, columns, data) => {
  if (!Array.isArray(data) || data.length === 0) {
      console.error("No hay datos disponibles para exportar.");   
    return;
  }

  const doc = new jsPDF();

  doc.text(title, 100, 16, null, null, "center");
  // doc.addImage("static/logoMam.png", "PNG", 15, 40, 180, 180);

  // Define los encabezados de la tabla
  const headers = columns.map(column => column.headerName);

  // Define los datos de la tabla
  const dataToExport = data.map(row => columns.map(col => row[col.field]));

  // Imprime dataToExport en la consola para depuración
  // console.log('Data to Export:', dataToExport);

  // Añade la tabla al PDF
  doc.autoTable({
    head: [headers],
    body: dataToExport,
    startY: 22,
    didDrawPage: (data) => {
      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;
      const footerText = "Unelca"
      // Fecha actual
      const date = new Date().toLocaleDateString();

      // Número de página
      const pageNumber = doc.internal.getNumberOfPages();
      
       // Texto del pie de página
      const footerText1 = `Fecha: ${date} / Página ${pageNumber} / ${footerText}`;

      // Pie de página
      doc.setFontSize(10);
      // Calcular la posición para alinear a la derecha
      const textWidth = doc.getTextWidth(footerText);
      const xPosition = pageWidth - 78 - textWidth; // Margen de 15 unidades desde el borde derecho

      doc.text(footerText1, xPosition, pageHeight - 10);
    }
  });

  // Guarda el PDF
  // doc.autoPrint({variant: 'non-conform'});   
  doc.save(`${title}.pdf`);
  // doc.save('autoprint.pdf');
}

